import { useEffect } from 'react'

const Hook = (ref, onScroll) => {
  useEffect(() => {
    let timer = null
    const refValue = ref.current
    function onHandlerScroll(evt) {
      if (onScroll && typeof onScroll === 'function') {
        onScroll(evt)
      }
      // clearTimeout(timer)
      // if (!refValue?.classList?.contains('activeScroll')) {
      //   refValue.classList.add('activeScroll')
      // }
      // timer = setTimeout(() => {
      //   refValue?.classList?.remove('activeScroll')
      // }, 1000)
    }
    refValue?.addEventListener('scroll', onHandlerScroll, false)
    return () => {
      clearTimeout(timer)
      refValue?.removeEventListener('scroll', onHandlerScroll)
    }
  }, [onScroll])
}

export default Hook
