import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import './index.scss'
import { qwebApi } from '@/apis/qwebApi'
import { InifiteCarousel, InifiteCarouselItem } from '@/components/Carousel/infinteCarousel/Index'
import GameListItem from '@/components/common/GameListItem/GameListItem'
const PremiumGameBanner = ({ className }) => {
  const onGotoRogue = () => {
    qwebApi({
      event: 'openBrowser',
      data: { type: 2, url: 'https://now.gg/play/browse' },
    })
  }
  const recommendedApps = useSelector((state) => state.app.recommendedApps)
  return (
    <div className={classNames(['premiumGameBanner-container', className])}>
      <div className="premiumGameBanner-left">
        <div className="premiumGameBanner-description">
          <div className="premiumGameBanner-description-item">
            <SvgIcon className="icon" size={1} icon="play-color" />
            <span className="text">
              <FormattedMessage id="oneClickInstantPlay" />
            </span>
          </div>
          <div className="premiumGameBanner-description-item">
            <SvgIcon className="icon" size={1} icon="upload-color" />
            <span className="text">
              <FormattedMessage id="noDownload" />
            </span>
          </div>
          <div className="premiumGameBanner-description-item">
            <SvgIcon className="icon" size={1} icon="game-color" />
            <span className="text">
              <FormattedMessage id="thousandsOfSupportedOnlineGames" />
            </span>
          </div>
        </div>
        <button onClick={onGotoRogue} className="premiumGameBanner-button">
          <FormattedMessage id="goRogue" />
        </button>
      </div>
      <div className="premiumGameBanner-right">
        <InifiteCarousel>
          {recommendedApps.slice(0, 10).map((app) => {
            return (
              <InifiteCarouselItem key={app.game_name} className="premiumGameBanner-carousel-item">
                <GameListItem
                  nameClass="premiumGameBanner-app-name"
                  categoryClass="premiumGameBanner-app-category"
                  showPlayBtn
                  showCloudBtn
                  imgPlaceholderClass="ratio-3-4"
                  game={app}
                  imageUrlGet={(game) => game.banner_url_v}
                />
              </InifiteCarouselItem>
            )
          })}
        </InifiteCarousel>
      </div>
    </div>
  )
}

export default PremiumGameBanner
