import * as types from './constant'

import { getIpCountry } from '@/apis/apps'


// Set app offline page action
export const setAppOfflinePage = (status = true) => {
  return {
    type: types.SET_SHOW_APP_OFFLINE_PAGE,
    payload: status,
  }
}

// Switch client loader action
export const switchLoader = (isLoader = true) => {
  let timer = null
  return (dispatch) => {
    clearInterval(timer)
    timer = setTimeout(() => {
      dispatch({
        type: types.SWITCH_LOADER,
        payload: isLoader,
      })
    }, 300)
  }
}

// Set local code action
export const setLocalCode = (localCode) => {
  let localCodeModify = localCode
  if (localCode === 'pt') {
    localCodeModify = 'pt-br'
  }
  if (localCode === 'cht') {
    localCodeModify = 'tw'
  }
  return {
    type: types.SET_APP_LOCAL_CODE,
    payload: localCodeModify,
  }
}

// Set language action
export const setLanguage = (lang) => {
  return {
    type: types.SET_APP_LANGUAGE,
    payload: lang,
  }
}

// Fetch country code action
export const fetchCountryCode = () => {
  return async (dispatch, getState) => {
    let countryCode = getState().system.countryCode
    try {
      const _result = await getIpCountry()
      countryCode = _result.country
    } catch (error) {}
    dispatch({
      type: types.SET_APP_COUNTRY_CODE,
      payload: countryCode,
    })
    return countryCode
  }
}

// Set location path action
export const setPageLocation = (location) => {
  return {
    type: types.SET_APP_LOCATION_PATH,
    payload: location,
  }
}

// Set client system config action
export const setclientSystemConfig = (config) => {
  return {
    type: types.SET_APP_CLIENT_SYSTEM_CONFIG,
    payload: config,
  }
}

// Set client mode action
export const setClientMode = (mode) => {
  return {
    type: types.SET_APP_CLIENT_MODE,
    payload: mode,
  }
}

// Set client mode action
export const setFullscreenState = (state) => {
  return {
    type: types.SET_FULLSCREEN_STATE,
    payload: state,
  }
}
