import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import RcTooltip from '@/components/tooltip/RcTooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Button from '@/components/common/Button/Button'
import ButtonGroup from '@/components/common/Button/ButtonGroup'

import useSupportCloudWrap from '@/hook/useSupportCloudWrap'

import { getButtonText } from '@/tool/buttonText'
import './index.scss'

const ActionButton = ({
  app,
  isInstalled,
  handleOnClick,
  className,
  size = 'default',
  mode,
  showIcon = false,
  contentFilter = null,
  pageLocation = 'commonPage',
  type = 'combo',
}) => {
  const intl = useIntl()
  const countryCode = useSelector((state) => state.system.countryCode)
  const buttons = []
  const wrapApp = useSupportCloudWrap(app)
  if (!wrapApp) {
    return buttons
  }
  const isAppDetailPage = pageLocation === 'appDetailPage'
  const isGoogleIcon = wrapApp.package_name === 'com.android.vending'
  const isJapan = countryCode === 'JP'
  let tooltipId = ''
  if (isAppDetailPage) {
    // 已安装
    if (isInstalled) {
      // 非详情页且非google play
      if (!isAppDetailPage && !isGoogleIcon) {
        tooltipId = 'playOnAppPlayer'
      }
    } else {
      // 未安装情况下判断是否来至于apk
      if (wrapApp.isInstallCDN) {
        tooltipId = 'googleInstallAppPlayerTip'
      } else {
        tooltipId = 'googleInstallTip'
      }
    }
  }
  const isGotoAppDetailPage = !isJapan && !!wrapApp.isAppDetailAccessable && !isAppDetailPage
  // 详情页或则在JP下显示全部按钮
  if (isJapan || isAppDetailPage) {
    if (wrapApp.showCloudBtn) {
      const { actionText, actionCode, redirectCode, buttonType, icon } = getButtonText(intl, {
        app: wrapApp,
        platforms: 'cloud',
        isGotoAppDetailPage,
      })
      buttons.push(
        <Button
          key="cloudButton"
          type={buttonType}
          icon={showIcon && icon}
          onClick={(evt) =>
            handleOnClick({ app: wrapApp, evt, gameType: 'cloud', actionCode, redirectCode })
          }
        >
          {actionText}
        </Button>
      )
    }
    if (wrapApp.showPlayBtn) {
      const { actionText, actionCode, redirectCode, buttonType, icon } = getButtonText(intl, {
        isInstalled,
        app: wrapApp,
        platforms: 'local',
        isGotoAppDetailPage,
      })
      buttons.push(
        <RcTooltip
          key="playButton"
          trigger="hover"
          disabled={!tooltipId}
          overlay={
            <span>
              <FormattedMessage id={tooltipId}></FormattedMessage>
            </span>
          }
        >
          <Button
            key="playButton"
            type={buttonType}
            icon={showIcon && icon}
            onClick={(evt) =>
              handleOnClick({ app: wrapApp, evt, gameType: 'simulator', actionCode, redirectCode })
            }
          >
            {!isAppDetailPage && wrapApp.showCloudBtn ? (
              <SvgIcon size={1} icon={'play'} className={['c-white']} />
            ) : (
              actionText
            )}
          </Button>
        </RcTooltip>
      )
    }
  } else {
    const { actionText, actionCode, redirectCode, buttonType, icon } = getButtonText(intl, {
      isInstalled,
      app: wrapApp,
      isGotoAppDetailPage,
    })
    buttons.push(
      <RcTooltip
        key="playButtonWithViewDetailButton"
        trigger="hover"
        disabled={!tooltipId}
        overlay={
          <span>
            <FormattedMessage id={tooltipId}></FormattedMessage>
          </span>
        }
      >
        <Button
          key="playButton"
          type={buttonType}
          icon={showIcon && icon}
          onClick={(evt) =>
            handleOnClick({ app: wrapApp, evt, gameType: 'simulator', actionCode, redirectCode })
          }
        >
          {actionText}
        </Button>
      </RcTooltip>
    )
  }
  return (
    <ButtonGroup
      size={size}
      mode={mode}
      className={classNames(['action-button-container', `action-button-${type}`, className])}
    >
      {contentFilter ? contentFilter(buttons) : buttons}
    </ButtonGroup>
  )
}

export default React.memo(ActionButton)
