/**
 * Game center browse by collection module
 */

import React from 'react'
import CollectionItem from '@/components/common/Collection/Item'
import bgColorList from '@/components/common/Collection/bgColor'
import SkeletonCollection from '@/skeletons/Collection'
import CommonCarouselSection from './CommonCarouselSection'

const Collections = ({ className, titleId, columns = 7, list, isLoading }) => {
  return list.length ? (
    <>
      <CommonCarouselSection
        linkTo={{
          pathname: '/collections',
        }}
        carouselKeyId={titleId}
        list={list}
        className={className}
        titleId={titleId}
        slidesPerView={list.length < columns ? list.length : columns}
        pagination={true}
        keyId="topic_name"
        isGroup={false}
      >
        {(item, { index }) => {
          return <CollectionItem topic={item} styleObj={bgColorList[index % bgColorList.length]} />
        }}
      </CommonCarouselSection>
    </>
  ) : (
    <SkeletonCollection column={columns} titleId={titleId} enable={isLoading} />
  )
}

export default React.memo(Collections)
