import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// actions
import { setInstalledApps, setLocalPerference, setInstalledPCGames } from '@/reducers/app/actions'
import { setClientUserConfig, setUserInfo } from '@/reducers/user/actions'
import { setPageLocation, setFullscreenState } from '@/reducers/system/actions'
import { pushAppStack } from '@/reducers/appdetail/actions'

import useInstalledApps from '@/hook/useInstalledApps'
import useInstalledPcGames from '@/hook/useInstalledPcGames'
import usePerference from '@/hook/usePerference'
import useUserToken from '@/hook/useUserToken'
import useClientUserConfig from '@/hook/useClientUserConfig'
import useGmQuery from '@/hook/useGmQueryRemote'
import useGotoDetailForClient from '@/hook/useGotoDetailForClient'
import useRouter from '@/hook/useRouter'
import useFullscreenState from '@/hook/useFullscreenState'
import useTopicsActions from '@/hook/useTopicsActions'
import useRecommendGameActions from '@/hook/useRecommendGameActions'
import useCloudGameActions from '@/hook/useCloudGameActions'
import useAllApps from '@/hook/useAllApps'
import usePushPromotesApps from '@/hook/usePushPromotesApps'
import { createTrackId } from '@/tool/track'
import { memoryCache } from '@/hook/useScrollMemory'
import { on, off } from '@/apis/qwebApi'

const Setup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const allApps = useAllApps()

  // Search logic between client and web
  useGmQuery()

  // Register Router event
  useRouter()

  // Fullscreen state
  const state = useFullscreenState()
  useEffect(() => {
    dispatch(setFullscreenState(state))
  }, [dispatch, state])

  // Page router event register for client
  useEffect(() => {
    const onRouterTo = ({ path, trigger }) => {
      dispatch(setPageLocation(path))
      if (trigger === 'client') {
        memoryCache.delete(`${path}-scroll-memory`)
        navigate({
          pathname: `/${path}`,
        })
      }
    }
    on('pageRouterTo', onRouterTo)
    return () => {
      off('pageRouterTo', onRouterTo)
    }
  }, [dispatch])

  // Jump to app detail
  const onOpenDetail = useCallback(
    async (appDetail) => {
      if (appDetail) {
        // $$isClear:whether to clear appStack，default true;$$from:from where,default search_bar(client search bar);$$state: extra info
        const { $$isClear, $$from = 'search_bar', $$state = {}, trackId, ...app } = appDetail

        let _app = app
        // 是否清空app detail数据stack
        let _isClear = $$isClear

        // 延迟进入详情页（解决从My Game进来页面会先进入home->detail）
        // let _delay = 100
        const packageName = appDetail.package_name
        if (!packageName) {
          return
        }
        /**
         * search_bar:从客户端search栏
         * my_game:从My Game数据来(type:pc,android)
         * agent:从bs5发送
         */
        const { type } = $$state
        switch ($$from) {
          case 'search_bar':
            _isClear = true
            createTrackId(trackId || $$from)
            break
          case 'my_game':
            dispatch(setPageLocation('myGame'))
            createTrackId(trackId || $$from)
            if (type === 'pc') {
              _app = { showPlayBtn: false, showPcBtn: false, showCloudBtn: false, ...app }
            } else if (type === 'android') {
              _app = allApps.find((game) => game.package_name === packageName)
            } else if (type === 'cloud') {
              _app = allApps.find((game) => game.package_name === packageName)
            }
            // appendTrackPath(type)
            break
          case 'bs5':
            _app = allApps.find((game) => game.package_name === packageName) || {
              showPlayBtn: true,
              package_name: packageName,
            }
            createTrackId(trackId || $$from)
            break
          default:
          // _delay = 0
        }
        dispatch(pushAppStack({ app: _app, $$isClear: _isClear, $$from, $$state }))
        // setTimeout(() => {
        navigate(`/app/${packageName}`, {
          state: {
            from: $$from,
            ...$$state,
          },
        })
        // }, _delay)
      }
    },
    [dispatch, allApps]
  )

  useGotoDetailForClient(onOpenDetail)

  // local perference
  const localPerference = usePerference()
  useEffect(() => {
    dispatch(setLocalPerference(localPerference))
  }, [localPerference, dispatch])

  // client config (one_button_config)
  const clientUserConfig = useClientUserConfig()
  useEffect(() => {
    dispatch(setClientUserConfig(clientUserConfig))
  }, [dispatch, clientUserConfig])

  // User info
  const useInfo = useUserToken()
  useEffect(() => {
    dispatch(setUserInfo(useInfo))
  }, [useInfo, dispatch])

  // Update installed app(install and uninstall)---only package name
  const installedApps = useInstalledApps()
  useEffect(() => {
    dispatch(setInstalledApps(installedApps))
  }, [installedApps, dispatch])

  // Update installed pc games(install and uninstall)---only package name
  const installedPcGames = useInstalledPcGames()
  useEffect(() => {
    dispatch(setInstalledPCGames(installedPcGames))
  }, [installedPcGames, dispatch])

  // topics info

  useTopicsActions()

  // recommend game to qt
  useRecommendGameActions()

  // instant play game to qt
  useCloudGameActions()

  usePushPromotesApps()

  return null
}

export default Setup
