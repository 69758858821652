import React from 'react'
import { useNavigate } from 'react-router-dom'
import FormattedText from '@/components/common/FormattedText/Index'
import BaseHeader from './BaseHeader'

import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import classNames from 'classnames'
const GamePageHeader = ({ title, titleId, count, onBack, className, showBack = true }) => {
  const navigate = useNavigate()
  const _onBack = () => {
    if (onBack) {
      onBack()
    } else {
      navigate(-1)
    }
  }

  return (
    <BaseHeader
      className={classNames(['gamePageHeader', className])}
      type="page"
    >
      {showBack ? <button className="gamePageHeaderLeft" onClick={_onBack}>
        <SvgIcon icon="arrow-left" className={['pointer', 'margin-right-10']} />
        <FormattedText title={title} id={titleId} />
      </button> : <FormattedText title={title} id={titleId} />}
      {count > 0 ? <FormattedText
        className='font16'
        id={count === 1 ? 'subHeadingGameCountForSingle' : 'subHeadingGameCount'}
        values={{ count }}
      /> : null}
    </BaseHeader>
  )
}

export default GamePageHeader
