import React, { useRef, useEffect } from 'react'
// import { useSelector } from 'react-redux'
// import { useDebounceFn } from 'ahooks'
import HomeContainer from './homeContainer/Index'
import GameCenterContainer from './gameCenterContainer/Index'
// import useScrollIntoView from './useScrollIntoView'
import useBackgroundImageScroll from '@/hook/useBackgroundImageScroll'
import useScrollMemory from '@/hook/useScrollMemory'
import useScrollBarActive from '@/hook/useScrollBarActive'
import { useGridBaseCount } from '@/hook/useViewport'
import { createTrackId } from '@/tool/track'
// import Utils from '@/Utils'
// import { qwebApi } from '@/apis/qwebApi'

import './index.scss'

const HomeGameCenter = () => {
  const containerRef = useRef(null)
  const homeContainerRef = useRef(null)
  const gameCenterContainerRef = useRef(null)

  const gridCount = useGridBaseCount()

  useBackgroundImageScroll(containerRef, { path: 'home' })
  useScrollMemory(containerRef, { key: 'home-scroll-memory' })
  useScrollBarActive(containerRef)

  useEffect(() => {
    createTrackId('home')
  }, [])
  return (
    <div className="home-game-center" ref={containerRef}>
      <HomeContainer className="breakScreen" ref={homeContainerRef} gridCount={gridCount} />
      <GameCenterContainer
        className="breakScreen margin-bottom-30"
        ref={gameCenterContainerRef}
        gridCount={gridCount}
      />
    </div>
  )
}

export default HomeGameCenter
