import React, { useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import AspectImage from '@/components/common/Image/AspectImage'
import { installOrOpenPCGame } from '@/actions/commonActions'
import Button from '@/components/common/Button/Button'
import { STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR } from '@/tool/constant'
import { onGameClickAction } from '@/actions/commonActions'
import useAppLaunchParams from '@/hook/useAppLaunchParams'
import useSupportCloudWrap from '@/hook/useSupportCloudWrap'
import { getButtonText } from '@/tool/buttonText'
import style from './resultItem.module.scss'
import classNames from 'classnames'
const ResultItem = ({ app, keyword, as = 'div' }) => {
  const intl = useIntl()
  const containerRef = useRef(null)
  const installedApps = useSelector((state) => state.app.installedApps)
  const _app = useSupportCloudWrap(app)
  const isInstalled = installedApps.includes(_app.package_name)

  /**
   * On click app event
   */
  const handleOnClick = useCallback(
    ({ app, evt, gameType, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        gameType,
        bannerLocation: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
        actionCode,
        redirectCode,
      })
    },
    [isInstalled]
  )

  const isInstalledPcGame = false
  // Install or open pc game
  const onInstallOrOpenPcGame = useCallback(
    ({ evt, url, packageName }) => {
      evt.preventDefault()
      evt.stopPropagation()
      installOrOpenPCGame({
        url,
        packageName,
        location: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
        isInstalled: isInstalledPcGame,
      })
    },
    [isInstalledPcGame]
  )

  // App actions list
  const buttonsJSX = useMemo(() => {
    const buttonJSX = []
    const nonAndroidGameDatas = _app?.non_android_game_data || []
    let pcGameButton = null
    if (nonAndroidGameDatas.length) {
      const firstData = nonAndroidGameDatas[0]
      pcGameButton = (
        <Button
          key="pcButton"
          type="primaryChange"
          icon="window"
          size="mini"
          className={style.actionButton}
          onClick={(evt) =>
            onInstallOrOpenPcGame({
              evt,
              url: firstData.download_url,
              packageName: _app.package_name,
            })
          }
        />
      )
    }
    if (_app.showCloudBtn) {
      const { actionCode, redirectCode, buttonType, icon, gameType } = getButtonText(intl, {
        app: _app,
        platforms: 'cloud',
        isGotoAppDetailPage: false,
      })
      buttonJSX.push(
        <Button
          size="mini"
          key="playOnCloudButton"
          type={buttonType}
          icon={icon}
          className={style.actionButton}
          onClick={(evt) => handleOnClick({ app: _app, evt, gameType, actionCode, redirectCode })}
        />
      )
    }
    if (_app.showPlayBtn) {
      const { actionText, actionCode, redirectCode, gameType, buttonType, icon } = getButtonText(
        intl,
        {
          isInstalled,
          app: _app,
          platforms: 'local',
          isGotoAppDetailPage: false,
        }
      )
      buttonJSX.push(
        <Button
          size="mini"
          key="playOnBsButton"
          type={buttonType}
          className={style.actionButton}
          icon={icon}
          onClick={(evt) => handleOnClick({ app: _app, evt, gameType, actionCode, redirectCode })}
        >
          <font>{actionText}</font>
        </Button>
      )
    }

    // Show steam game button
    if (_app.showPcBtn) {
      buttonJSX.push(
        <Button
          size="mini"
          key="checkPcGameButton"
          titleId="checkPcGame"
          type="success"
          className={style.actionButton}
          onClick={(evt) => handleOnClick({ app: _app, evt, gameType: 'steam' })}
        />
      )
    }
    buttonJSX.reverse()
    buttonJSX.splice(1, 0, pcGameButton)
    return buttonJSX
  }, [_app, handleOnClick, intl, isInstalled])

  // Click item event(direct to app detail)
  const { gameType, actionCode, redirectCode } = useAppLaunchParams(_app)
  const onItemClick = useCallback(
    (evt) => {
      handleOnClick({ app: _app, evt, gameType, actionCode, redirectCode })
    },
    [gameType, actionCode, handleOnClick]
  )
  const AsDOm = as
  const gameNameHtml = keyword
    ? _app.game_name.replace(new RegExp(keyword, 'gi'), (match) => {
        return `<span class="${style.matchString}">${match}</span>`
      })
    : _app.game_name
  return (
    <AsDOm
      className={classNames([style.container, { pointer: !_app.steam_supported }])}
      ref={containerRef}
      onClick={(evt) => !_app.steam_supported && onItemClick(evt)}
    >
      <div className={style.left}>
        <AspectImage aspectClass="ratio-1-1" src={_app.icon_url} />
      </div>
      <div className={style.right}>
        <div className={style.title} dangerouslySetInnerHTML={{ __html: gameNameHtml }}></div>
        <div className={style.actions}>{buttonsJSX}</div>
      </div>
    </AsDOm>
  )
}

export default ResultItem
