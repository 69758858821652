import React, { useCallback, useEffect, useState, useMemo,useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Virtual } from 'swiper'
import classNames from 'classnames'
import GameCarouselTwo from '@/components/CarouselSection/GameCarouselSectionForHomeBanner'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import CommonBannerWrap from "@/components/banner/CommanBannerWrap"
import BannerOne from '@/components/banner/bannerOne/Index'
import InstantPlayBanner from '@/components/banner/instantPlayBanner/Index'
import PremiumGameBanner from '@/components/banner/premiumGameBanner/Index'
import { onGameClickAction } from '@/actions/commonActions'
import SkeletonGameList from '@/skeletons/GameList'
import SkeletonHomeBanner from '@/skeletons/HomeBanner'


import { STAT_HOME_PAGE_BANNER, GOOGLE_PLAY_PCK } from '@/tool/constant'
import instantPlatyIcon from '@/images/instantPlay/instant_play.png'
import instantPlatyBg from '@/images/instantPlay/instant_play_bg.png'
import gpbgImage from '@/images/search/gp_bg_v2.png'

import styles from './banner.module.scss'

const HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY = 'homeBannerActiveIndex'

const Banner = ({ className, gridCount = 4 }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [bannerGame, setBannerGame] = useState(null)
  const [isInitPage, setIsInitPage] = useState(true)
  const [activeIndex, setActiveIndex] = useState(
    +window.sessionStorage.getItem(HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY) || 0
  )

  const rotationBanners = useSelector((state) => state.app.rotationBanners)

  const rotationBannersWithInstantPlay = useMemo(() => {
    if (rotationBanners.length === 0) {
      return []
    }
    const instantPlayApp = {
      game_name: intl.formatMessage({ id: 'instantPlay' }),
      cloud_supported: false,
      icon_url: instantPlatyIcon,
      // webs使用
      showCloudBtn: false,
      showPlayBtn: true,
      bg_image: `${window.location.origin}${process.env.PUBLIC_URL}${instantPlatyBg}`,
      isAppDetailAccessable: false,
      isGoRogue: true
    }
    // const premiumGameApp = {
    //   game_name: intl.formatMessage({ id: 'premiumGame' }),
    //   cloud_supported: false,
    //   icon_url: gpbgImage,
    //   // webs使用
    //   showCloudBtn: false,
    //   showPlayBtn: true,
    //   bg_image: `${window.location.origin}${process.env.PUBLIC_URL}${gpbgImage}`,
    //   isAppDetailAccessable: false,
    //   isPremiumGame: true
    // }
    const _rotationBanners=[...rotationBanners]
    _rotationBanners.splice(3,0,instantPlayApp)
    return _rotationBanners
  }, [intl, rotationBanners])

  const loadingStore = useSelector((state) => state.loading)

  // 打开游戏
  const handleOnClick = useCallback(
    ({ evt, gameType, isInstalled, app, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation: STAT_HOME_PAGE_BANNER,
        actionCode,
        redirectCode,
      })
    },
    [dispatch]
  )

  // 鼠标移入事件
  const onHoverEnter = useCallback(
    (item, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  // 页面初始化
  useLayoutEffect(() => {
    if (rotationBannersWithInstantPlay.length) {
      const app = rotationBannersWithInstantPlay[activeIndex]
      const isGooglePlay = app.package_name === GOOGLE_PLAY_PCK
      const isGooglePlayAndEnterGP = isGooglePlay && process.env['REACT_APP_SHOW_GP_STORE']
      if (isGooglePlayAndEnterGP) {
        return
      }
      setBannerGame(app)
    }
  }, [rotationBannersWithInstantPlay, activeIndex])

  useEffect(() => {
    window.sessionStorage.setItem(HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY, activeIndex)
  }, [activeIndex])

  const bannerJSX=useMemo(()=>{
    if(bannerGame){
      if(bannerGame.isGoRogue){
        return <InstantPlayBanner />
      }
      if(bannerGame.isPremiumGame){
        return <PremiumGameBanner />
      }
      return <BannerOne banner={bannerGame} handleOnClick={handleOnClick} />
    }else{
      return <SkeletonHomeBanner enable={loadingStore.gameCenterRotationLoading} />
    }
  },[bannerGame,loadingStore.gameCenterRotationLoading])

  return (
    <CommonBannerWrap className={classNames([styles.container, className])} banner={bannerGame} bannerName="home">
      <div className={styles.bannerInfo}>
        {bannerJSX}
      </div>
      {rotationBannersWithInstantPlay.length ? (
        <ErrorFallback>
          <GameCarouselTwo
            className={styles.bannerList}
            carouselKeyId="homeBannerGames"
            list={rotationBannersWithInstantPlay}
            imgPlaceholderClass="ratio-1-1"
            bannerLocation={STAT_HOME_PAGE_BANNER}
            showCloudBtn
            showPlayBtn
            showInstalledState
            showDetailIcon={false}
            slidesPerView={gridCount + 1}
            onHoverEnter={onHoverEnter}
            // onItemClick={onHoverEnter}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            slideConfig={{
              modules: [Virtual],
              virtual: true,
              onActiveIndexChange: (swiper) => {
                if (!isInitPage) {
                  setActiveIndex(swiper.activeIndex)
                }
              },
              onAfterInit: (swiper) => {
                setTimeout(() => {
                  const prevApp = rotationBannersWithInstantPlay[activeIndex]
                  const isGooglePlay = prevApp?.package_name === GOOGLE_PLAY_PCK
                  const isGooglePlayAndEnterGP = isGooglePlay && process.env['REACT_APP_SHOW_GP_STORE']
                  if (isGooglePlayAndEnterGP) {
                    setActiveIndex(0)
                  } else {
                    swiper.slideTo(activeIndex, 0)
                  }
                  setIsInitPage(false)
                }, 100)
              },
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          className={styles.skeletonContainer}
          column={gridCount + 1}
          showHeader={false}
          gameItemProps={{ showSubTitle: false }}
          enable={loadingStore.gameCenterRotationLoading}
        />
      )}
    </CommonBannerWrap>
  )
}

export default Banner
