import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import PremiumGameCarousel from './components/gameCarousel/Banner'
import SkeletonGameList from '@/skeletons/GameList'
import useBackgroundImageScroll from '@/hook/useBackgroundImageScroll'
import useScrollMemory from '@/hook/useScrollMemory'
// import './index.scss'
import { STAT_PREMIUM_GAME_PAGE_LIST } from '@/tool/constant'
import { useGridBaseCount } from '@/hook/useViewport'
import Categorys from './components/categorys/Index'
import EmptyContainer from './components/empty/Index'
import { createTrackId } from '@/tool/track'

import './index.scss'
// 记录页面的状态
const PremiumGame = () => {
  const containerRef = useRef()
  const intl = useIntl()
  const loadingStore = useSelector((state) => state.loading)
  // 云游戏
  const categoryMap = useSelector((state) => state.cloud.categoryMap)
  const countryCode = useSelector((state) => state.system.countryCode)
  const gridCount = useGridBaseCount()

  const categorys = Object.keys(categoryMap)

  useBackgroundImageScroll(containerRef, { path: 'premiumGame' })
  useScrollMemory(containerRef, { key: 'premium-scroll-memory' })

  useEffect(() => {
    createTrackId('premiumGame')
  }, [])

  if (loadingStore.premiumLoading === false && categorys.length === 0) {
    return (
      <div className="premium-empty">
        <EmptyContainer />
      </div>
    )
  }

  return (
    <div className="premium-container" ref={containerRef}>
      <PremiumGameCarousel gridCount={gridCount} className="breakScreen margin-bottom-50" />
      {/* category */}
      <Categorys className="breakScreen" />
      {/* Category Games in your region */}
      {categorys.length > 0 ? (
        categorys.map((category) => {
          const list = categoryMap[category]
          const isOtherGenres = category === 'otherGenres'
          if (list.length <= 2 && !isOtherGenres) {
            return null
          }
          const title = isOtherGenres
            ? intl.formatMessage({ id: 'otherGenres' })
            : intl.formatMessage({ id: 'subHeadingCategory' }, { category })
          return (
            <GameCarouselSection
              key={category}
              className="breakScreen oneBannerHeight"
              carouselKeyId={category.replace(/\s/g, '_')}
              titleClass="spaceBetween"
              title={title}
              list={list}
              linkTo={{
                pathname: '/premiumByCategory',
                search: `category=${category}&title=${title}&from=premium`,
              }}
              showCloudBtn
              bannerLocation={STAT_PREMIUM_GAME_PAGE_LIST}
              imgPlaceholderClass="ratio-3-4"
              imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
              slidesPerView={gridCount - 1}
              isGroup={false}
            />
          )
        })
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-3-4"
          titleId="androidGamesLike"
          column={gridCount - 1}
          enable={loadingStore.premiumLoading}
        />
      )}
      <div className="margin-bottom-30"></div>
    </div>
  )
}

export default PremiumGame
