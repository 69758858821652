import React, { useEffect } from 'react'
import classNames from 'classnames'
import { RouterProvider, } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import router from '@/router'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import { isQtClient } from '@/tool/qwebchannel/utils'
import { ViewportProvider } from '@/hook/useViewport'

// actions
import { initGameCenterApps, fetchSuggestedApps, initCloudGamesApps } from '@/reducers/app/actions'
import { fetchCountryCode } from '@/reducers/system/actions'

const App = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.user.theme)
  const countryCode = useSelector((state) => state.system.countryCode)
  const clientMode = useSelector((state) => state.system.mode)
  const fullscreenState = useSelector((state) => state.system.fullscreenState)

  // Fetch country code
  useEffect(() => {
    dispatch(fetchCountryCode())
  }, [dispatch])

  // Fetch cloud game apps
  useEffect(() => {
    dispatch(initCloudGamesApps())
  }, [dispatch])

  // Fetch suggested apps
  useEffect(() => {
    if (countryCode) {
      dispatch(fetchSuggestedApps(countryCode))
    }
  }, [dispatch, countryCode])

  useEffect(() => {
    // Begin to fetch initialization data
    if (countryCode) {
      dispatch(initGameCenterApps())
    }
  }, [dispatch, countryCode, clientMode])

  // Prevent tab key function
  useEffect(() => {
    function onKeyDownHandler(evt) {
      if (evt.keyCode === 9) {
        if (evt.preventDefault) {
          evt.preventDefault()
        } else {
          evt.returnValue = false
        }
      }
    }
    window.addEventListener('keydown', onKeyDownHandler)
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler)
    }
  }, [])

  return (
    <ViewportProvider>
      <div className={classNames(['appContainer', 'contentInner', theme, { isQtClient, fullscreenState }])}>
        <ErrorFallback>
          <RouterProvider router={router} />
        </ErrorFallback>
      </div>
    </ViewportProvider>
  )
}

export default App
