import React, { useCallback, useRef } from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { containerValueFinder } from '@/tool'

const Scrollload = ({
  // 总数居
  list = [],
  // 一次加载条目
  numberOfItems = 6,
  // 子组件渲染函数
  render,
  // 滚动容器
  scrollContainer,
  // 加载阈值
  bottomTop = { bottom: 300, top: 100 },
}) => {
  const [pageNum, setPageNum] = useState(1)
  const timerRef = useRef(null)
  const observerRef = useRef(null)

  const pageSize = numberOfItems
  // 显示的条目
  const showList = useMemo(() => {
    return list.slice(0, pageNum * pageSize)
  }, [list, pageNum, pageSize])
  // 是否已经加载完
  const isLoadFinished = useMemo(() => {
    return list.length === showList.length
  }, [list, showList])

  const clearRefFn = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect()
      observerRef.current = null
    }
    clearInterval(timerRef.current)
  }, [])

  useEffect(() => {
    return clearRefFn
  }, [clearRefFn])
  // 加载函数
  const observerCallback = useCallback(() => {
    clearRefFn()
    const observeDom = containerValueFinder(scrollContainer)
    if (!observeDom) {
      return
    }
    timerRef.current = setTimeout(() => {
      const containerHeight = observeDom.clientHeight //获取可见区域高度
      const scrollHight = observeDom.scrollHeight //获取全文高度
      const scrollTop = observeDom.scrollTop //获取被卷去的高度
      if (scrollHight - scrollTop - containerHeight <= bottomTop.bottom) {
        setPageNum((page) => page + 1)
      }
    }, 50)
  }, [bottomTop, scrollContainer, clearRefFn])

  // 注册事件
  useEffect(() => {
    const observeDom = containerValueFinder(scrollContainer)
    if (!isLoadFinished && observeDom) {
      observerRef.current = new MutationObserver(observerCallback)
      observerRef.current.observe(observeDom, { attributes: true, childList: true, subtree: true })
      observeDom.addEventListener('scroll', observerCallback)
      observerCallback()
    }
    return () => {
      observeDom && observeDom.removeEventListener('scroll', observerCallback)
    }
  }, [observerCallback, isLoadFinished, scrollContainer])
  return render(showList, pageSize)
}

export default React.memo(Scrollload)
