import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import PureGameGridLayout from '@/components/layout/gameGridLayout/Pure'
import Banner from '@/pages/home/Banner'
import SkeletonGameList from '@/skeletons/GameList'
import { shuffleRecommendedApps } from '@/reducers/app/actions'

import { arrayShuffle } from '@/tool'

import {
  STAT_HOME_PAGE_TRENDINGGAMES,
  STAT_GAMECENTER_PAGE_RECOMMENDED,
} from '@/tool/constant'

const HomeContainer = ({ className, gridCount }, ref) => {
  const dispatch = useDispatch()
  const countryCode = useSelector((state) => state.system.countryCode)
  const loadingStore = useSelector((state) => state.loading)
  // 置顶游戏（selected_game）
  const topApps = useSelector((state) => state.app.topApps)
  // 最新游戏（latest_game）
  const recommendedApps = useSelector((state) => state.app.recommendedApps)



  // strategy apps show
  const recommendedAppsForShow = useMemo(() => {
    if (countryCode === 'JP') {
      return recommendedApps
    }
    const topApps = [...recommendedApps].filter((app, o) => {
      return !!app.banner_url_v
    })
    const lastApps = [...recommendedApps].filter((app, o) => {
      return !app.banner_url_v
    })
    return topApps.concat(lastApps)
  }, [recommendedApps, countryCode])

  // refresh recommended for you apps
  const onRefresh = useCallback(() => {
    dispatch(shuffleRecommendedApps(arrayShuffle([...recommendedApps])))
  }, [recommendedApps, dispatch])

  return <div className={classNames(['home-container', className])} ref={ref}>
    <Banner className="homeListBanner" gridCount={gridCount} />
    {/* Recommended For You */}
    {recommendedAppsForShow.length ? (
      <ErrorFallback>
        <GameCarouselSection
          className='oneBannerHeight'
          carouselKeyId="recommendedGames"
          titleId="recommendedGames"
          bannerLocation={STAT_GAMECENTER_PAGE_RECOMMENDED}
          list={recommendedAppsForShow}
          linkTo={{
            pathname: '/apps',
            search: 'type=recommendedApps&from=gameCenter&titleId=recommendedGames',
          }}
          onRefresh={onRefresh}
          // pagination={false}
          showPlayBtn
          showCloudBtn
          imgPlaceholderClass="ratio-3-4"
          imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
          showInstalledState
          slidesPerView={gridCount - 1}
          isGroup={false}
        />
      </ErrorFallback>
    ) : (
      <SkeletonGameList
        imgPlaceholderClass="ratio-3-4"
        titleId="recommendedGames"
        column={gridCount - 1}
        enable={loadingStore.gameCenterHomeLoading}
      />
    )}
    {/* Trending Games */}
    {topApps.length ? (
      <ErrorFallback>
        <GameCarouselSection
          className='twoSquareHeight'
          carouselKeyId="homeTopGames"
          titleClass="spaceBetween"
          titleId="cloudGamesLike"
          linkTo={{
            pathname: '/apps',
            search: 'type=topApps&from=home&titleId=cloudGamesLike',
          }}
          list={topApps}
          showInstalledState
          imgPlaceholderClass="ratio-1-1"
          bannerLocation={STAT_HOME_PAGE_TRENDINGGAMES}
          showCloudBtn
          showPlayBtn
          slidesPerView={1}
          groupNumber={gridCount * 2}
          renderItem={({ slide, itemProps, groupNumber }) => {
            return (
              <PureGameGridLayout
                gameItemProps={itemProps}
                list={slide}
                columns={gridCount}
                numberOfItems={groupNumber}
              />
            )
          }}
        />
      </ErrorFallback>
    ) : (
      <SkeletonGameList
        imgPlaceholderClass="ratio-1-1"
        titleId="cloudGamesLike"
        column={gridCount}
        row={2}
        enable={loadingStore.gameCenterHomeLoading}
      />
    )}
  </div>
}

export default React.forwardRef(HomeContainer)
