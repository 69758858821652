import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import instantPlatyGoat from '@/images/instantPlay/instant_play_goat.png'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
import { STAT_HOME_PAGE_BANNER } from '@/tool/constant'
import './index.scss'
import { qwebApi } from '@/apis/qwebApi'
const InstantPlayBanner = ({ className }) => {
  const onGotoRogue = () => {
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V3, {
      banner_location: STAT_HOME_PAGE_BANNER,
      event_type: STATS_POINT.BSX_INSTANTPALY_APP_EXPORENOW,
    })
    qwebApi({
      event: 'openBrowser',
      data: {
        type: 2,
        url: 'https://now.gg/play/browse?utm_source=now.gg-partner&utm_medium=client&utm_campaign=BlueStacksX',
      },
    })
  }
  return (
    <div className={classNames(['instantPlayBanner-container', className])}>
      <div className="instantPlayBanner-left">
        <div className="instantPlayBanner-description">
          <div className="instantPlayBanner-description-item">
            <SvgIcon className="icon" size={1} icon="play-color" />
            <span className="text">
              <FormattedMessage id="oneClickInstantPlay" />
            </span>
          </div>
          <div className="instantPlayBanner-description-item">
            <SvgIcon className="icon" size={1} icon="upload-color" />
            <span className="text">
              <FormattedMessage id="noDownload" />
            </span>
          </div>
          <div className="instantPlayBanner-description-item">
            <SvgIcon className="icon" size={1} icon="game-color" />
            <span className="text">
              <FormattedMessage id="thousandsOfSupportedOnlineGames" />
            </span>
          </div>
        </div>
        <button onClick={onGotoRogue} className="instantPlayBanner-button">
          <FormattedMessage id="exporeNow" />
        </button>
      </div>
      <div className="instantPlayBanner-right">
        <img src={instantPlatyGoat} alt="instant_play_goat" />
      </div>
    </div>
  )
}

export default InstantPlayBanner
