import { createBrowserRouter, Navigate } from 'react-router-dom'

// import Home from '@/components/Home/Home'
import CloudGame from '@/pages/cloudGame/Index'
import CloudGameList from '@/pages/cloudGame/List'
// import NotFound from '@/pages/notFound/Index'
import Search from '@/pages/search/Index'
import GoogleSearch from '@/pages/googleSearch/Index'
import Apps from '@/pages/apps/Index'
import ByTopicApps from '@/pages/apps/ByTopicApps'
import Collections from '@/pages/collections/Index'
import GameCenter from '@/pages/gameCenter/Index'
import Home from '@/pages/home_gameCenter/Index'
import AppDetail from '@/pages/appDetail/Index'
import React from 'react'
import CommonAnimation from '@/components/common/Animation/CommonAnimation'

import PremiumGame from '@/pages/premiumGame/Index'
import PremiumGameList from '@/pages/premiumGame/List'

export const routes = [
  {
    path: '/',
    index: true,
    element: <Navigate to="/home" replace />,
  },
  {
    path: '/gameCenter',
    element: <GameCenter />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/app/:packageName',
    element: <AppDetail />,
  },
  {
    path: '/cloudGame',
    element: <CloudGame />,
  },
  {
    path: '/cloudGameByCategory',
    element: <CloudGameList />,
  },
  {
    path: '/premiumGame',
    element: <PremiumGame />,
  },
  {
    path: '/premiumGameByCategory',
    element: <PremiumGameList />,
  },
  {
    path: '/search',
    element: <Search />,
  },
  {
    path: '/googleSearch',
    element: <GoogleSearch />,
  },
  {
    path: '/apps',
    element: <Apps />,
  },
  {
    path: '/byTopicApps',
    element: <ByTopicApps />,
  },
  {
    path: '/collections',
    element: <Collections />,
  },
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonAnimation />,
    errorElement: <Navigate to="/home" replace />,
    children: routes.map((route) => ({
      index: route.path === '/home',
      path: route.path,
      element: route.element,
    })),
  },
])

export default router
