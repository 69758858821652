/**
 * Register search event for client
 */

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { on, off, qwebApi } from '@/apis/qwebApi'
import { getSearchResultTransformer } from '@/transformers/transformerApi'
import { createTrackId } from '@/tool/track'
import { getSupportCloudApp } from './useSupportCloudWrap'
const Hook = () => {
  const navigate = useNavigate()
  const installedApps = useSelector((state) => state.app.installedApps)
  const cloudGames = useSelector((state) => state.cloud.cloudGames)
  const countryCode = useSelector((state) => state.system.countryCode)
  const searchMutation = useMutation(['searchQueryClient'], (query) =>
    getSearchResultTransformer(query)
  )
  // Register client search event
  useEffect(() => {
    const onClientRealSearch = async (res) => {
      const apps = await searchMutation.mutateAsync(res.query)
      const searchApps = apps.slice(0, 12).map((app) => {
        const _app = getSupportCloudApp(app, cloudGames)
        return {
          ..._app,
          isInstalled: installedApps.includes(app.package_name),
          isAppDetailAccessable: app.isAppDetailAccessable && countryCode !== 'JP',
        }
      })
      qwebApi({ event: 'pushRealSearchResult', data: searchApps })
    }
    on('clientRealSearch', onClientRealSearch)
    return () => off('clientRealSearch', onClientRealSearch)
  }, [searchMutation, installedApps, countryCode, cloudGames])
  // Notify to jump to search page by client
  useEffect(() => {
    const onClientGotoSearch = ({ query, $$from = 'Launcher_SearchPromotes', $$state = {} }) => {
      createTrackId($$from)
      setTimeout(() => {
        navigate(
          {
            pathname: '/search',
            search: `query=${query}`,
          },
          {
            state: {
              from: $$from,
              ...$$state,
            },
          }
        )
      }, 0)
    }
    on('clientGotoSearch', onClientGotoSearch)
    return () => {
      off('clientGotoSearch', onClientGotoSearch)
    }
  }, [navigate])
}

export default Hook
