import React, { useLayoutEffect, useEffect } from 'react'
import { useLocation, useOutlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { qwebApi } from '@/apis/qwebApi'
// import './index.css'
import Setup from '@/components/setup/Index'
import NotNetwork from '@/pages/notNetwork/Index'

function Animate() {
  const location = useLocation()
  const currentOutlet = useOutlet()
  const showOfflinePage = useSelector((state) => state.system.showOfflinePage)

  useLayoutEffect(() => {
    qwebApi({ event: 'changeBannerBackgroundImage' })
  }, [location.pathname])

  useLayoutEffect(() => {
    console.timeEnd('Router switch time cost.')
    return ()=>{
      console.time('Router switch time cost.')
    }
  }, [location.pathname])

  useEffect(() => {
    if (showOfflinePage) {
      qwebApi({ event: 'changeBannerBackgroundImage' })
    }
  }, [showOfflinePage])
  return (
    <>
      <Setup />
      {showOfflinePage ? <NotNetwork /> : currentOutlet}
    </>
  )
}

export default Animate
