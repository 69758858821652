/**
 * Show all game list module
 */

import React from 'react'
import GridLayout from '@/components/layout/gridLayout/Index'
import GameListItem from '@/components/common/GameListItem/GameListItem'
const GameGridLayout = ({
  gameItemProps = {},
  ...layoutProps
}) => {
  return (
    <GridLayout
      as='main'
      {...layoutProps}
    >
      {(item) => {
        return (
          <GameListItem
            game={item}
            {...gameItemProps}
          />
        )
      }}
    </GridLayout>
  )
}

export default React.memo(GameGridLayout)
