// import { v4 as uuid } from 'uuid'
let trackUid = null
let trackPrefix = null
let trackPaths = []

/**
 * Get track ID, if none that create a new track ID
 * @returns Track ID
 */
export const getTrackId = () => {
  if (trackPrefix) {
    const trackId = [trackPrefix, ...trackPaths, trackUid].filter(Boolean)
    return trackId.join('--')
  }
  console.warn(
    'Pls call `createTrackId` before use `getTrackId`,provide a default trackId:`uuid()`'
  )
  // return uuid()
  return 'bsx10'
}

/**
 * Create a new track ID
 * @param {string} id Track ID
 * @returns New track ID
 */
export const createTrackId = (prefix = 'track') => {
  // if (newUUID) {
  //   if (newUUID === true) {
  //     trackUid = uuid()
  //   } else {
  //     trackUid = newUUID
  //   }
  // } else {
  //   trackUid = null
  // }
  trackPrefix = prefix
  trackPaths = []
  console.log('New track ID:'+getTrackId())
}

/**
 * Append track path
 * @param {string} path Track path
 */
export const appendTrackPath = (path) => {
  if (path) {
    return trackPaths.push(path)
  }
  return 0
}

/**
 * Remove track ID
 */
export const resetTrackId = () => {
  trackUid = null
  trackPaths = []
  trackPrefix = null
}
