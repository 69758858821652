import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'

import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import Button from '@/components/common/Button/Button'
// Page I18N Mapping(default:page)
const PageIntlMap = {
  gameCenter: 'androidGames',
  home: 'home',
  cloudGame: 'instantPlay',
}
const PageIntlMapProxy = new Proxy(PageIntlMap, {
  get(obj, key) {
    return obj[key] || 'page'
  },
})
const NotNetwork = () => {
  const tabLocation = useSelector((state) => state.system.location)
  const onRefreshClick = () => {
    window.location.href = `/${tabLocation}`
  }

  return (
    <ErrorFallback>
      <CommonHelpTip
        textTitle="noInternetConnect"
        textValue="offlineMessageBySearch"
        className="appNoNetworkContainer"
        imgSrc="noNetwork"
        localSvg={true}
        values={{
          page: <FormattedMessage id={PageIntlMapProxy[tabLocation]} />,
        }}
        extra={() => (
          <Button
            type="primary"
            className="margin-top-20 font16 minw200"
            onClick={onRefreshClick}
            titleId="refresh"
          />
        )}
      />
    </ErrorFallback>
  )
}

export default NotNetwork
