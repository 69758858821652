/**
 * Reduc combine
 */
import { combineReducers } from 'redux'
import appReducer from './app/reducer'
import systemReducer from './system/reducer'
import userReducer from './user/reducer'
import cloudReducer from './cloud/reducer'
import appdetailReducer from './appdetail/reducer'
import loadingReducer from './loading/reducer'
import supportsReducer from './supports/reducer'

const reducer = combineReducers({
  app: appReducer,
  system: systemReducer,
  user: userReducer,
  cloud: cloudReducer,
  appdetail: appdetailReducer,
  loading: loadingReducer,
  supports: supportsReducer
})

export default reducer
