import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { getButtonText } from '@/tool/buttonText'
const Hook = (app) => {
  const intl = useIntl()
  const installedApps = useSelector((state) => state.app.installedApps)
  const isInstalled = installedApps.includes(app.package_name)
  const { actionText, redirectCode, gameType, actionCode } = getButtonText(intl, {
    isInstalled,
    app,
    isGotoAppDetailPage: true
  })
  return { gameType, actionCode, actionText, isInstalled, redirectCode }
}

export default Hook
