import React from 'react'
import Image from '@/components/common/Image/Image'
import AspectContainer from '@/components/common/AspectContainer/Index'

const AspectImage = ({ aspectClass, className, ...props }) => {
  return (
    <AspectContainer className={className} aspectClass={aspectClass}>
      <Image alt="AspectImage" {...props}/>
    </AspectContainer>
  )
}

export default AspectImage
