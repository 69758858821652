import { emit, qwebApi } from '@/apis/qwebApi'
import store from '@/store'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
import { getPcDownloadUrlWithTrack } from '@/tool/nonAndroidUtils'
import { getTrackId } from '@/tool/track'

// Game Type Mapping
const gameTypeMap = {
  simulator: 'Android_Game',
  local: 'Android_Game',
  cloud: 'Cloud_Game',
  steam: 'Steam_Game',
}

// Event type for stat
const getEventType = (gameType, isInstalled) => {
  if (gameType === 'simulator') {
    if (isInstalled) {
      return 'play_on_android'
    } else {
      return 'install_on_android'
    }
  }
  if (gameType === 'cloud') {
    return 'play_on_cloud'
  }
  if (gameType === 'steam') {
    return 'play_on_steam'
  }
}

// Record stat data
export const getAppClickStats = ({ app, bannerLocation, isInstalled, gameType }) => {
  // console.log('=================', getTrackId(), '=================')
  return {
    trackId: getTrackId(),
    app_pkg: app.package_name,
    banner_location: bannerLocation,
    event_type: getEventType(gameType, isInstalled),
    game_type: gameTypeMap[gameType],
  }
}

// On game clicked
export const onGameClickAction = async ({
  app,
  isInstalled,
  evt,
  gameType = 'simulator',
  bannerLocation,
  actionCode,
  redirectCode,
}) => {
  evt.preventDefault()
  evt.stopPropagation()
  // Record data
  const statsData = getAppClickStats({ app, isInstalled, bannerLocation, gameType })

  const isAppDetailAccessable =
    app.isAppDetailAccessable && store.getState().system.countryCode !== 'JP'

  // Required infomation for launch app
  const isOpenInBrowserAction = app.action === 'open_in_browser'
  const gamePlayInfo = {
    ...app,
    isAppDetailAccessable,
    redirectCode,
    // 配置数据适配
    action: isOpenInBrowserAction ? 'UserBrowser' : app.action,
    action_value: isOpenInBrowserAction ? app.action_value || app.download_url : app.action_value,
  }
  if (app.isOpenInBrowser && gamePlayInfo.action_value) {
    gamePlayInfo.action_value = getPcDownloadUrlWithTrack(gamePlayInfo.action_value)
  }
  // Jump to /googleSearch(if it's google play app)
  // if (redirectCode === 'AppGoogleSearch' || app.package_name === GOOGLE_PLAY_PCK) {
  if (redirectCode === 'AppGoogleSearch') {
    emit('onRoute', {
      action: 'push',
      pathname: '/googleSearch',
      state: app,
    })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, {
      ...gamePlayInfo,
      ...statsData,
      actionCode,
      event_type: STATS_POINT.BSX_GPSTORE_CLICKED,
    })
    return
  }

  // Judge to Instant Play
  if (redirectCode === 'InstantPlay') {
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V3, {
      banner_location: bannerLocation,
      event_type: STATS_POINT.BSX_INSTANTPALY_APP_VIEWDETAIL,
    })
    qwebApi({
      event: 'pageRouterTo',
      data: {
        path: 'cloudGame',
      },
    })
    return
  }
  // Judge to App Detail page
  if (redirectCode === 'AppDetail') {
    emit('openDetailPage', {
      package_name: app.package_name,
      $$isClear: false,
      $$from: 'html',
      $$state: { type: statsData.banner_location },
      ...app,
    })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK, {
      ...gamePlayInfo,
      ...statsData,
      actionCode,
      event_type: STATS_POINT.BSX_JUMP_TO_DETAIL_PAGE,
    })
    return
  }
  // gameType:simulator ,local:Android Game,cloud:Cloud Game,steam PC Game
  if (gameType === 'cloud') {
    qwebApi({
      event: 'openCloudGame',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else if (gameType === 'steam') {
    qwebApi({
      event: 'openStreamGame',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else if (gameType === 'simulator' || gameType === 'local') {
    qwebApi({
      event: 'installOrPlayAppforVm',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else {
    console.warn("Don't match action,please check your action params")
  }
}

// Install or open pc game
export const installOrOpenPCGame = ({ location, url, packageName, isInstalled }) => {
  // const installedPcGames = store.getState().app.installedPcGames || []
  // const isInstalled = installedPcGames.includes(packageName)
  const downloadUrl = getPcDownloadUrlWithTrack(url)
  const trackId = getTrackId()
  if (isInstalled) {
    qwebApi({
      event: 'launcherPCGame',
      data: {
        trackId,
        banner_location: location,
        packageName,
        url: downloadUrl,
      },
    })
  } else {
    qwebApi({
      event: 'installPCGame',
      data: {
        trackId,
        banner_location: location,
        packageName,
        url: downloadUrl,
      },
    })
  }
}
