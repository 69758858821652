import React, { useState, useEffect, useTransition } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import CategoryItem from './Item'
import styles from './index.module.scss'
import SkeletionsCloudGameCategory from '@/skeletons/cloudGameCategory/Index'
const Categorys = ({ className }) => {
  const categorys = useSelector((state) => state.cloud.categorys)
  const loadingStore = useSelector((state) => state.loading)
  const [style, setStyle] = useState({})
  const [isPending, startTransition] = useTransition()
  useEffect(() => {
    requestIdleCallback(() => {
      startTransition(() => {
        setStyle({
          backgroundImage: `radial-gradient(24.52% 46.74% at 50.33% 47.92%, rgba(81, 165, 201, 0.2) 0%, rgba(79, 165, 201, 0) 100%),
        radial-gradient(21.19% 36.37% at 63.08% 56.52%, rgba(179, 214, 97, 0.2) 0%, rgba(179, 214, 97, 0) 100%),
        radial-gradient(22.34% 37.44% at 32.98% 55.76%, rgba(255, 66, 165, 0.2) 0%, rgba(255, 66, 165, 0) 100%)`,
        })
      })
    })
  }, [])
  return (
    <div className={classNames([styles.container, className])} style={style}>
      <div className={styles.header}>
        <FormattedMessage id="discoverYourFavoriteGamingGenre" />
      </div>
      <div className={styles.content}>
        {categorys.length > 0 ? (
          categorys.map((category) => {
            return <CategoryItem key={category.title} category={category} />
          })
        ) : (
          <SkeletionsCloudGameCategory count={10} enable={loadingStore.cloudGameLoading} />
        )}
      </div>
    </div>
  )
}

export default Categorys
