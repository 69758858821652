import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { qwebApi } from '@/apis/qwebApi'
import { getSupportCloudApp } from '@/hook/useSupportCloudWrap'

const Hook = () => {
  const cloudGames = useSelector((state) => state.cloud.cloudGames)
  const suggestedApps = useSelector((state) => state.app.suggestedApps)
  const searchItem = useSelector((state) => state.app.searchItem)

  useEffect(() => {
    if (suggestedApps.length) {
      qwebApi({
        event: 'pushPromotesApps',
        data: {
          apps: suggestedApps.map((app) => getSupportCloudApp(app, cloudGames)),
          default_search_term: searchItem,
        },
      })
    }
  }, [cloudGames, suggestedApps, searchItem])
}

export default Hook
