import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ActionButton from '@/components/actionButton/Index'
import Button from '@/components/common/Button/Button'
import { useParams, useLocation } from 'react-router-dom'
import { getAppDetail } from '@/apis/detail'
import { qwebApi } from '@/apis/qwebApi'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import style from './index.module.scss'

import {
  STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES,
  STAT_APP_DETAIL_PAGE_SIMILAR_GAMES,
  STAT_APP_DETAIL_PAGE_BANNER,
} from '@/tool/constant'
import AspectImage from '@/components/common/Image/AspectImage'
import Banner from './components/banner/Banner'
import Describtion from './components/describtion/Describtion'
import GameFeature from './components/gameFeature/GameFeature'
import Gallery from './components/gallery/Index'
import { setAppDetail } from '@/reducers/appdetail/actions'
import { useNavigate } from 'react-router-dom'
import { popAppStack } from '@/reducers/appdetail/actions'
import { onGameClickAction } from '@/actions/commonActions'
import { isYoutubeUrl, transformToEmbedUrl } from '@/tool/youtube'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import SplitLine from '@/components/common/splitLine/Index'
import PageLayout from '@/components/layout/pageLayout/Index'
import SkeletonAppDetail from '@/skeletons/AppDetail'
import { installOrOpenPCGame } from '@/actions/commonActions'
import { useGridBaseCount } from '@/hook/useViewport'

const AppDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageLayoutRef = useRef()
  const params = useParams()
  const location = useLocation()
  const intl = useIntl()
  const gridCount = useGridBaseCount()

  const [isBannerVisible, setIsBannerVisible] = useState(true)

  const pageLocation = useSelector((state) => state.system.location)
  const appDetail = useSelector((state) => state.appdetail.map[params.packageName])
  const appStacks = useSelector((state) => state.appdetail.appHeaps)

  const fullscreenState = useSelector((state) => state.system.fullscreenState)

  const isSupportPcVersion = useSelector((state) => state.supports.isSupportPcVersion)

  // 当前查看app
  const { app: bannerGame } = useMemo(() => {
    return appStacks[appStacks.length - 1] || {}
  }, [appStacks])

  const installedApps = useSelector((state) => state.app.installedApps)
  const isInstalled = installedApps.includes(bannerGame?.package_name)

  // const installedPcGames = useSelector((state) => state.app.installedPcGames)
  const isInstalledPcGame = false //installedPcGames.includes(bannerGame?.package_name)

  const setDefaultData = () => {
    if (bannerGame) {
      dispatch(
        setAppDetail({
          key: params.packageName,
          value: {
            game_overview: {
              icon_url: bannerGame.icon_url,
              app_name: bannerGame.game_name,
              app_banner_url: bannerGame.bg_image,
            },
            similar_apps: [],
            similar_category_games: [],
          },
        })
      )
    }
  }
  // 请求app详情
  const { isFetching, isSuccess, isFetched } = useQuery(
    ['getAppDetail', params.packageName],
    () => getAppDetail({ packageName: params.packageName }),
    {
      onSettled(data) {
        if (data && data.data) {
          const appDetail = data.data
          // appDetail.game_overview = {
          //   ...(appDetail.game_overview || {}),
          //   app_banner_url: bannerGame?.bg_image || appDetail.game_overview.app_banner_url,
          // }
          dispatch(setAppDetail({ key: params.packageName, value: appDetail }))
        } else {
          setDefaultData()
        }
      },
    }
  )

  const videoLink = useMemo(() => {
    const gallery = appDetail?.gallery || {}
    const { nowgg_videos } = gallery
    let videoLink = nowgg_videos?.desktop[0] || ''

    // eslint-disable-next-line no-prototype-builtins
    if (nowgg_videos?.hasOwnProperty('game_specific_video')) {
      if (!nowgg_videos?.game_specific_video) videoLink = ''
    }
    return videoLink
  }, [appDetail])
  const app_banner_url = appDetail?.game_overview?.app_banner_url
  // 初始化
  useEffect(() => {
    if (app_banner_url || videoLink) {
      qwebApi({
        event: 'changeBannerBackgroundImage',
        data: {
          url: app_banner_url,
          video: videoLink,
          playCount: 1,
          sleep: 1000,
          top: window.getRealMeasurement(pageLayoutRef.current?.getScrollTop() || 0),
          tileRatio: 0.83333,
          path: 'appDetail',
        },
      })
    }
  }, [videoLink, app_banner_url, location.key])

  // 游戏截图及视频资源
  const screenshotsAndVideos = useMemo(() => {
    if (appDetail) {
      const { gp_videos = [], screenshots = [] } = appDetail.gallery || {}
      let _videos = [] //Array.isArray(gp_videos_with_thumb) ? gp_videos_with_thumb : []
      if (_videos.length === 0) {
        _videos = gp_videos.map((video) => {
          return {
            video_url: video,
          }
        })
      }
      const gpVideos = _videos.map((video) => {
        const url = isYoutubeUrl(video.video_url)
          ? transformToEmbedUrl(video.video_url)
          : video.video_url
        return {
          src: url,
          video: url,
          type: 1,
          posturl: video.thumb_url,
        }
      })

      const screenshotsImages = screenshots.map((shot) => {
        return {
          src: shot.image,
          ...shot,
          type: 2,
        }
      })
      return [...gpVideos, ...screenshotsImages]
    }
    return []
  }, [appDetail])

  // 返回
  const _onGotoBack = useCallback(() => {
    const state = location.state
    // 说明由searchBar进入appdetail页面
    // if (state?.from === 'search_bar') {
    //   navigate('/home', { replace: true })
    // } else {
    //   navigate(-1)
    // }
    // 返回判断客户端行为
    if (state?.returnCode) {
      qwebApi({
        event: 'onReturnToClient',
        data: {
          returnCode: state.returnCode,
        },
      })
    } else {
      navigate(-1)
    }
    setTimeout(() => {
      dispatch(popAppStack())
    }, 500)
  }, [dispatch, location.state])

  // gallery点击事件
  const _onGalleryClick = useCallback(
    (index) => {
      qwebApi({
        event: 'onOpenGallery',
        data: {
          gallery: screenshotsAndVideos,
          index,
        },
      })
    },
    [screenshotsAndVideos]
  )

  // 滚动事件
  const _onContainerScroll = useCallback((e) => {
    qwebApi({
      event: 'onPageScroll',
      data: {
        x: window.getRealMeasurement(e.target.scrollLeft),
        y: window.getRealMeasurement(e.target.scrollTop),
        path: 'appDetail',
      },
    })
  }, [])

  // 点击项目(appDetail 无需加actionCode)
  const handleOnClick = useCallback(
    ({ evt, gameType, app }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        gameType,
        bannerLocation: STAT_APP_DETAIL_PAGE_BANNER,
        redirectCode: 'AppLauncher',
      })
    },
    [isInstalled]
  )

  const onVisibleChange = useCallback((visible) => {
    setIsBannerVisible(visible)
  }, [])

  // Install or open pc game
  const onInstallOrOpenPcGame = useCallback(
    ({ url, packageName }) => {
      installOrOpenPCGame({
        url,
        packageName,
        location: STAT_APP_DETAIL_PAGE_BANNER,
        isInstalled: isInstalledPcGame,
      })
    },
    [isInstalledPcGame]
  )

  const actionButtonJSX = useCallback(
    ({ size }) => {
      if (appDetail) {
        const nonAndroidGameDatas = appDetail?.non_android_game_data || []
        let pcButtonJsx = null
        if (pageLocation !== 'cloudGame' && isSupportPcVersion && nonAndroidGameDatas.length) {
          const firstData = nonAndroidGameDatas[0]
          pcButtonJsx = (
            <Button
              key="pcButton"
              type="primary"
              icon="window"
              onClick={() =>
                onInstallOrOpenPcGame({
                  url: firstData.download_url,
                  packageName: bannerGame.package_name,
                })
              }
            >
              {intl.formatMessage({
                id: isInstalledPcGame ? 'launchPcVersion' : 'installPcVersion',
              })}
            </Button>
          )
        }
        return (
          <ActionButton
            className={[style.actionButton]}
            app={bannerGame}
            isInstalled={isInstalled}
            pageLocation="appDetailPage"
            type="normal"
            showIcon
            size={size}
            handleOnClick={handleOnClick}
            contentFilter={(buttons) => {
              buttons.reverse()
              buttons.splice(1, 0, pcButtonJsx)
              return buttons
            }}
          />
        )
      }
      return null
    },
    [
      bannerGame,
      isInstalled,
      handleOnClick,
      appDetail,
      onInstallOrOpenPcGame,
      intl,
      isInstalledPcGame,
      isSupportPcVersion,
      pageLocation,
      location.key,
    ]
  )

  const renderHeader = useMemo(() => {
    const showBannerExtra = isBannerVisible === false
    return (
      <>
        <div onClick={_onGotoBack} className={style.headerContent}>
          <div className={style.headerContentLeft}>
            <SvgIcon className={style.headerBackIcon} size={1} icon="go-back" />
            {showBannerExtra && appDetail?.game_overview && (
              <AspectImage
                className={style.headerGameIcon}
                src={appDetail.game_overview?.icon_url}
                alt="appPageBannerImage"
              />
            )}
            <FormattedMessage id="goBack" />
          </div>
        </div>
        {showBannerExtra && actionButtonJSX({ position: 'endCenter', size: 'small' })}
      </>
    )
  }, [isBannerVisible, appDetail, _onGotoBack, actionButtonJSX])

  const supports = useMemo(() => {
    const list = []
    const nonAndroidGameDatas = isSupportPcVersion ? appDetail?.non_android_game_data || [] : []
    if (bannerGame?.showPlayBtn) {
      list.push('android')
    }
    if (nonAndroidGameDatas.length) {
      list.push('window')
    }
    if (bannerGame?.showCloudBtn) {
      list.push('cloud')
    }
    return list
  }, [bannerGame, appDetail, isSupportPcVersion])

  return (
    <PageLayout
      onScroll={_onContainerScroll}
      className={classNames([style.container, { fullscreenState }])}
      renderHeader={renderHeader}
      ref={pageLayoutRef}
    >
      {!appDetail ? (
        isFetched ? (
          <CommonHelpTip
            textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
            imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
            localSvg
          />
        ) : (
          <SkeletonAppDetail enable={isFetching} count={4} />
        )
      ) : (
        <div className={style.contentWrap}>
          {/* banner */}
          <ErrorFallback>
            <Banner
              packageName={params.packageName}
              className={style.banner}
              isInstalled={isInstalled}
              appOverview={appDetail.game_overview}
              bannerLocation={STAT_APP_DETAIL_PAGE_BANNER}
              onVisibleChange={onVisibleChange}
              actionButton={actionButtonJSX({ size: 'large' })}
              supports={supports}
            />
          </ErrorFallback>
          {/* screenshots & video */}
          {screenshotsAndVideos.length > 0 ? (
            <ErrorFallback>
              <GameCarouselSection
                className={style.screenshots}
                carouselKeyId="appDetailScreenshotsAndVideo"
                titleClass={classNames(['spaceBetween', style.screenshotsTitle])}
                titleId="screenshotsAndVideo"
                list={screenshotsAndVideos}
                slidesPerView={'auto'}
                isGroup={false}
                refresh={false}
                slideConfig={{
                  virtual: false,
                  simulateTouch: true,
                  observer: true,
                  slidesPerGroup: 1,
                }}
                gap={32}
                renderItem={({ slide, index }) => {
                  return (
                    <Gallery
                      className={[
                        style.screenshotsGallery_1,
                        {
                          [style.screenshotsGallery_1]: index === 0,
                          [style.screenshotsGallery_1]: slide.type === 1,
                        },
                      ]}
                      // imgPlaceholderClass="ratio-62-35"
                      gallery={slide}
                      src={slide.src}
                      type={slide.type}
                      posturl={slide.posturl}
                      onClick={() => _onGalleryClick(index)}
                    />
                  )
                }}
              />
            </ErrorFallback>
          ) : null}
          {/* app describtion */}
          {appDetail.game_overview &&
          (appDetail.game_overview.game_description ||
            appDetail.game_overview.google_play_description) ? (
            <ErrorFallback>
              <SplitLine />
              <Describtion describtion={appDetail.game_overview} className={style.describtion} />
            </ErrorFallback>
          ) : null}

          {/* feature apps */}
          {appDetail.game_features && Object.keys(appDetail.game_features).length > 0 ? (
            <ErrorFallback>
              <SplitLine />
              <GameFeature features={appDetail.game_features} className={style.gameFeature} />
            </ErrorFallback>
          ) : null}

          {/* more strategy games */}
          {appDetail.similar_category_games.length > 0 &&
          appDetail.similar_category_games_meta?.title ? (
            <ErrorFallback>
              <SplitLine />
              <GameCarouselSection
                className={style.moreStrategyGames}
                carouselKeyId="appDetailMoreStrategyGames"
                titleClass="spaceBetween"
                title={appDetail.similar_category_games_meta.title}
                imgPlaceholderClass="ratio-1-1"
                bannerLocation={STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES}
                linkTo={{
                  pathname: '/apps',
                  search: `type=moreStrategyGames&title=${appDetail.similar_category_games_meta.title}&from=appDetail&apk=${params.packageName}`,
                }}
                showCloudBtn
                showPlayBtn
                list={appDetail.similar_category_games}
                slidesPerView={gridCount}
                isGroup={false}
                refresh={false}
              />
            </ErrorFallback>
          ) : null}

          {/* similar games */}
          {appDetail.similar_apps.length > 0 && appDetail.similar_apps_meta?.title ? (
            <ErrorFallback>
              <SplitLine />
              <GameCarouselSection
                className={style.similarGames}
                carouselKeyId="appDetailSimilarGames"
                titleClass="spaceBetween"
                title={appDetail.similar_apps_meta.title}
                imgPlaceholderClass="ratio-1-1"
                bannerLocation={STAT_APP_DETAIL_PAGE_SIMILAR_GAMES}
                linkTo={{
                  pathname: '/apps',
                  search: `type=similarGames&title=${appDetail.similar_apps_meta.title}&from=appDetail&apk=${params.packageName}`,
                }}
                showCloudBtn
                showPlayBtn
                list={appDetail.similar_apps}
                slidesPerView={gridCount}
                isGroup={false}
                refresh={false}
              />
            </ErrorFallback>
          ) : null}
        </div>
      )}
    </PageLayout>
  )
}

export default React.memo(AppDetail)
