import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  cloudGames: [],
  categorys: [],
  categoryMap: {},
}
export default produce((state, { type, payload = [] }) => {
  let sortGames = []
  let categorys = []
  let categoryMap = {}
  let noGenreGames = []
  let otherGenersGames = []
  switch (type) {
    case types.FETCH_CLOUDGAMES_APPS:
      // 按gameRank降序
      sortGames = payload
        .filter((app) => app.appRank && app.appRank !== 0)
        .sort((a, b) => {
          const flag = a.appRank - b.appRank
          if (flag > 0) {
            return -1
          }
          if (flag < 0) {
            return 1
          }
          return 0
        })
      // 游戏标签-去重
      sortGames.forEach((app) => {
        if (app.genre && !categorys.find((cat) => cat.title === app.genre)) {
          categorys.push({
            title: app.genre,
            icon: app.icon_url,
          })
        }
      })
      // 标签游戏映射表
      categoryMap = categorys.reduce((map, cat) => {
        map[cat.title] = sortGames.filter((app) => app.genre === cat.title)
        return map
      }, {})
      // 无genre游戏
      noGenreGames = sortGames.filter((app) => !app.genre)
      // 标签下游戏少于3的游戏集合
      otherGenersGames = Object.keys(categoryMap).reduce((games, category) => {
        const categoryGames = categoryMap[category] || []
        if (categoryGames.length <= 2) {
          games.push(...categoryGames)
        }
        return games
      }, noGenreGames)
      if (otherGenersGames.length) {
        categoryMap['otherGenres'] = otherGenersGames
      }
      state.cloudGames = sortGames
      state.categorys = categorys
      state.categoryMap = categoryMap
      break
    default: {
      /* empty */
    }
  }
}, INIT_STATE)
