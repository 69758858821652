import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { FormattedMessage } from 'react-intl'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import GooglePlayButtonLight from '@/components/common/Button/googlePlayLight/Index'
import { getSearchResultTransformer } from '@/transformers/transformerApi'

import { STAT_SEARCH_PAGE_RESULT } from '@/tool/constant'
import { memoryCache } from '@/hook/useScrollMemory'
import { qwebApi } from '@/apis/qwebApi'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
import SkeletonAppList from '@/skeletons/AppList'
import { useGridBaseCount } from '@/hook/useViewport'
import style from './index.module.scss'

const SearchResults = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const query = params.get('query') || ''
  const gridCount = useGridBaseCount()
  const { isFetching, data, isSuccess, isFetched } = useQuery(
    ['searchQuery', query],
    () => getSearchResultTransformer(query, { cacheSeed: 'search_page_key' }),
    {
      initialData: [],
      enabled: !!query,
    }
  )
  const onBack = () => {
    const state = location.state
    // if (state.from === 'search_bar') {
    //   navigate('/home', { replace: true })
    // } else {
    //   navigate(-1)
    // }
    // 返回判断客户端行为
    if (state?.returnCode) {
      qwebApi({
        event: 'onReturnToClient',
        data: {
          returnCode: state.returnCode,
        },
      })
    }else{
      navigate(-1)
    }
  }
  const onSearchInGooglePlayClick = useCallback(() => {
    qwebApi({ event: 'onSearchInGooglePlay', data: { query } })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, {
      event_type: STATS_POINT.BSX_SEARCH_IN_GP_CLICKED,
      query,
      banner_location: STAT_SEARCH_PAGE_RESULT,
    })
  }, [query])

  const headerJSX = (props) => {
    return (
      <GamePageHeader
        onBack={onBack}
        title={
          <>
            <FormattedMessage id="searchResultsFor" />
            <span className="margin-left-5">
              "{query.length > 20 ? `${query.substring(0, 20)}...` : query}"
            </span>
          </>
        }
        count={data.length}
        {...props}
      />
    )
  }

  return (
    <div className={style.container}>
      <GooglePlayButtonLight onClick={onSearchInGooglePlayClick} className={style.searchButton} />
      {isFetching ? (
        <SkeletonAppList
          className={['breakScreen', 'margin-bottom-30']}
          imgPlaceholderClass="ratio-1-1"
          headerRender={headerJSX}
          column={gridCount}
          row={2}
          enable={isFetching}
        />
      ) : (
        <GameGridLayout
          overscrollBehavior="none"
          gameItemProps={{
            imgPlaceholderClass: 'ratio-1-1',
            showPlayBtn: true,
            showCloudBtn: true,
            showInstalledState: true,
            showPcBtn: true,
            bannerLocation: STAT_SEARCH_PAGE_RESULT,
          }}
          list={data}
          contentInnerClass={['breakScreen', 'margin-bottom-30']}
          columns={gridCount}
          headerRender={headerJSX({ className: 'breakScreen' })}
          prefix={(list) => {
            let nofoundJsx = null
            if (list.length === 0 && isFetched) {
              nofoundJsx = (
                <CommonHelpTip
                  textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
                  imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
                  localSvg
                />
              )
            }
            return (
              <div
                className={style.helpBox}
                style={{ height: list.length === 0 ? 'calc(100% - 2px)' : 'unset' }}
              >
                {nofoundJsx}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

export default React.memo(SearchResults)
