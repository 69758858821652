import React, { useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PageLayout from '@/components/layout/pageLayout/Index'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import RefreshIcon from '@/components/Carousel/components/RefreshIcon'
import FormattedText from '@/components/common/FormattedText/Index'
import { qwebApi } from '@/apis/qwebApi'
import { shuffleRecommendedApps } from '@/reducers/app/actions'
import { arrayShuffle } from '@/tool'
import { STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED } from '@/tool/constant'

import RecentSearch from './components/recentSearch/Index'
import Search from './components/search/Index'
import style from './index.module.scss'
import gpbgImage from '@/images/search/gp_bg_v2.png'
import { useGridBaseCount } from '@/hook/useViewport'
import { createTrackId } from '@/tool/track'
const GoogleSearch = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Recommended Games
  const recommendedApps = useSelector((state) => state.app.recommendedApps)


  const gridCount = useGridBaseCount()

  useEffect(() => {
    qwebApi({
      event: 'changeBannerBackgroundImage',
      data: {
        url: `${window.location.origin}${process.env.PUBLIC_URL}${gpbgImage}`, //state?.full_hd_image,
        tileRatio: 0.85,
        path:'googleSearch'
      },
    })
  }, [])

  useEffect(() => {
    createTrackId('home')
  }, [])

  // Switch client search-bar
  useEffect(() => {
    qwebApi({ event: 'setSearchVisibility', data: { action: 'hide' } })
    return () => {
      qwebApi({ event: 'setSearchVisibility', data: { action: 'show' } })
    }
  }, [])

  // Refresh recommended apps
  const onRefresh = useCallback(() => {
    dispatch(shuffleRecommendedApps(arrayShuffle([...recommendedApps])))
  }, [recommendedApps, dispatch])

  // Custom title render jsx
  const renderHeader = () => {
    return (
      <BaseHeader>
        <span id="recommendedGames">
          <FormattedText id="recommendedGames" />
        </span>
        <RefreshIcon onRefresh={onRefresh} />
      </BaseHeader>
    )
  }
  return (
    <PageLayout className={[style.container]} onBack={() => navigate(-1)}>
      <div className={style.searchContainer}>
        <Search />
      </div>
      <div className={style.content}>
        <RecentSearch className={[style.recentSearches]} gridCount={gridCount} />
        {/* Recommended For You */}
        {recommendedApps.length ? (
          <ErrorFallback>
            <GameCarouselSection
              // slideConfig={{ spaceBetween: 43 }}
              className={[style.recommendedForYou]}
              carouselKeyId="recommendedGames"
              titleId="recommendedGames"
              bannerLocation={STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED}
              list={recommendedApps}
              renderHeader={renderHeader}
              pagination={false}
              showPlayBtn
              showCloudBtn
              imgPlaceholderClass="ratio-1-1"
              showInstalledState
              slidesPerView={gridCount}
              isGroup={false}
              gap={28}
            />
          </ErrorFallback>
        ) : null}
      </div>
    </PageLayout>
  )
}

export default GoogleSearch
