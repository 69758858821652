import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import { useGridBaseCount } from '@/hook/useViewport'
import { STAT_PREMIUM_GAME_LIST_PAGE } from '@/tool/constant'

const PremiumGameList = () => {
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const category = searchParams.get('category')
  const categoryMap = useSelector((state) => state.cloud.categoryMap)
  const countryCode = useSelector((state) => state.system.countryCode)


  const gridCount = useGridBaseCount()

  const appList = categoryMap[category] || []

  return (
    <GameGridLayout
      overscrollBehavior='none'
      gameItemProps={{
        imgPlaceholderClass: 'ratio-3-4',
        showCloudBtn: true,
        bannerLocation: STAT_PREMIUM_GAME_LIST_PAGE,
        imageUrlGet: (game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)
      }}
      list={appList}
      contentInnerClass={['breakScreen', 'margin-bottom-30']}
      columns={gridCount - 1}
      headerRender={() => {
        return (
          <GamePageHeader
            title={title}
            count={appList.length}
            className="breakScreen"
          />
        )
      }}
    />
  )
}

export default PremiumGameList
