import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  gameCenterRotationLoading: true,
  gameCenterHomeLoading: true,
  cpiAppsForQuestLoading: true,
  cloudGameLoading: true,
  premiumLoading: true,
  junsGameLoading: true,
  appPageDetailsLoading: true,
  appLauncherSearchLoading: true,
  topicDatasLoading: true,
  suggestedAppsLoading: true,
  sidePanelLoading: true,
}
const userReducer = produce((state, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_GAME_CENTER_ROTATION_LOADING: {
      state.gameCenterRotationLoading = payload
      break
    }
    case types.SET_GAME_CENTER_HOME_LOADING: {
      state.gameCenterHomeLoading = payload
      break
    }
    case types.SET_CPI_APPS_FOR_REQUEST_LOADING: {
      state.cpiAppsForQuestLoading = payload
      break
    }
    case types.SET_CLOUD_GAME_LOADING: {
      state.cloudGameLoading = payload
      break
    }
    case types.SET_PREMIUM_GAME_LOADING: {
      state.premiumLoading = payload
      break
    }
    case types.SET_JUNS_GAME_LOADING: {
      state.junsGameLoading = payload
      break
    }
    default: { /* empty */ }
  }
}, INIT_STATE)

export default userReducer
