import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const cloudGames = useSelector((state) => state.cloud.cloudGames)

  useEffect(() => {
    qwebApi({
      event: 'pushCloudToClient',
      data: cloudGames.slice(0, 12),
    })
  }, [cloudGames])

  return cloudGames
}

export default Hook
