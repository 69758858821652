import React from 'react'
import { useNavigate } from 'react-router'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import AspectImage from '@/components/common/Image/AspectImage'
import styles from './index.module.scss'
import { useState } from 'react'
const CategoryItem = ({ category }) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'subHeadingCategory' }, { category: category.genre })
  const [isLoad, setIsLoaded] = useState(false)
  const onItemClick = () => {
    navigate({
      pathname: '/premiumGameByCategory',
      search: `category=${category.genre}&title=${title}&from=premiumGame`,
    })
  }
  const _onLoad = () => {
    setIsLoaded(true)
  }
  return (
    <div className={styles.categoryItem} onClick={onItemClick}>
      <div className={styles.categoryItemInner}>
        <div className={classNames([styles.logo, { [styles.isLoad]: isLoad }])}>
          <AspectImage aspectClass="ratio-1-1" src={category.icon} onLoad={_onLoad} />
        </div>
        <span className={styles.title}>{category.genre}</span>
      </div>
    </div>
  )
}

export default CategoryItem
