import React, { useMemo, useCallback, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useHover } from 'ahooks'
import { useIntl } from 'react-intl'

import AspectImage from '../Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Button from '@/components/common/Button/Button'
import MarqueeText from '@/components/common/marqueeText/Index'

import useSupportCloudWrap from '@/hook/useSupportCloudWrap'

import { getButtonText } from '@/tool/buttonText'
import { GOOGLE_PLAY_PCK } from '@/tool/constant'

import style from './index.module.scss'

const GameListItem = ({
  className,
  nameClass,
  categoryClass,
  onImageLoad,
  onImageError,
  showSubTitle = true,
  showInstalledState,
  showPlayBtn = true,
  showCloudBtn,
  showTypeIcon,
  showDetailIcon = true,
  imgPlaceholderClass,
  isVideo,
  game,
  onClick,
  onHoverEnter,
  onRootClick = () => {},
  bannerLocation,
  imageUrlGet = false,
  hoverImageUrlGet = false,
  lazyRoot = null,
  lazyRootMargin,
  bootGameOnClick = true,
  hasHoverAction = false,
  mouseEnterDelay = 200,
  lazy = true,
  enableObserve,
}) => {
  const intl = useIntl()
  const hoverRef = useRef(null)
  const hoverTimerRef = useRef(null)
  const installedApps = useSelector((state) => state.app.installedApps)
  const countryCode = useSelector((state) => state.system.countryCode)
  const _game = useSupportCloudWrap(game)
  const isInstalled = installedApps.includes(_game.package_name)

  const isGooglePlay = _game.package_name === GOOGLE_PLAY_PCK

  const isGooglePlayAndEnterGP = isGooglePlay && process.env['REACT_APP_SHOW_GP_STORE']

  const isJapan = countryCode === 'JP'

  const isHovering = useHover(hoverRef, {
    onEnter: () => {
      if (hasHoverAction && onHoverEnter) {
        hoverTimerRef.current = setTimeout(() => onHoverEnter(_game), mouseEnterDelay)
      }
    },
    onLeave: () => {
      if (hasHoverAction) {
        clearTimeout(hoverTimerRef.current)
      }
    },
  })

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimerRef.current)
    }
  }, [])

  // 点击项目
  const handleOnClick = useCallback(
    ({ evt, gameType, actionCode, redirectCode }) => {
      if (onClick) {
        onClick(_game, evt)
      }
      if (bootGameOnClick) {
        onGameClickAction({
          app: _game,
          isInstalled,
          evt,
          gameType,
          bannerLocation,
          actionCode,
          redirectCode,
        })
      }
    },
    [_game, isInstalled, bannerLocation, onClick, bootGameOnClick]
  )

  // 获取图片url值
  const imageUrl = useMemo(() => {
    return (
      (imageUrlGet && imageUrlGet(_game)) ||
      _game.icon_url ||
      _game.banner_url ||
      _game.banner_url_v ||
      _game.game_tile_url ||
      _game.img_url
    )
  }, [_game, imageUrlGet])

  // 获取hover图片url值
  const hoverImageUrl = useMemo(() => {
    return (hoverImageUrlGet && hoverImageUrlGet(_game)) || imageUrl
  }, [_game, hoverImageUrlGet, imageUrl])

  // 是否显示游戏按钮
  const hasItemBtn = showPlayBtn || showCloudBtn

  // 是否显示查看详情按钮
  const hasDetailAction = useMemo(() => {
    if (!showDetailIcon || !_game.isAppDetailAccessable || isJapan) {
      return false
    }
    return true
  }, [_game, isJapan, showDetailIcon])

  // 图片策略展示
  const imageJSX = useMemo(() => {
    const realSrc = isVideo
      ? _game.video_url
      : hasHoverAction && isHovering
      ? hoverImageUrl
      : imageUrl

    const buttonJSX = []
    // 日本区域按照双按钮逻辑处理
    if (isJapan) {
      if (showCloudBtn && _game.showCloudBtn) {
        const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
          app: _game,
          platforms: 'cloud',
          isGotoAppDetailPage: false,
        })
        buttonJSX.push(
          <Button
            key="playOnCloudButton"
            className={classNames([style.itemImgButton])}
            type={buttonType}
            onClick={(evt) => handleOnClick({ evt, gameType: 'cloud', actionCode, redirectCode })}
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
          </Button>
        )
      }
      if (showPlayBtn && _game.showPlayBtn) {
        const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
          isInstalled,
          app: _game,
          platforms: 'local',
          isGotoAppDetailPage: false,
        })
        buttonJSX.push(
          <Button
            key="playOnBsButton"
            className={classNames([style.itemImgButton])}
            type={buttonType}
            onClick={(evt) =>
              handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode })
            }
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
          </Button>
        )
      }
    } else {
      const { actionText, actionCode, redirectCode, buttonType } = getButtonText(intl, {
        isInstalled,
        app: _game,
        isGotoAppDetailPage: _game.isAppDetailAccessable,
      })
      buttonJSX.push(
        <Button
          key="installButton"
          className={classNames([style.itemImgButton])}
          type={buttonType}
          onClick={(evt) => handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode })}
        >
          {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
        </Button>
      )
    }
    let detailClickJSX = null
    if (hasDetailAction) {
      detailClickJSX = (
        <div
          className={style.detailClickPanel}
          onClick={(evt) =>
            handleOnClick({
              evt,
              gameType: '',
              actionCode: '',
              redirectCode: isGooglePlay ? 'AppLauncher' : 'AppDetail',
            })
          }
        >
          <SvgIcon size={1.5} icon="zoom-in" className={style.detailIcon} />
        </div>
      )
    }
    return (
      <div
        className={classNames([style.slideUp, style.overlayContainer, 'gameItemOverlayContainer'])}
        ref={hoverRef}
      >
        {/* 图片自适应布局 */}
        <AspectImage
          aspectClass={
            typeof imgPlaceholderClass === 'function'
              ? imgPlaceholderClass(_game)
              : imgPlaceholderClass
          }
          isVideo={isVideo}
          src={realSrc}
          lazy={lazy}
          enableObserve={enableObserve}
          root={lazyRoot}
          rootMargin={lazyRootMargin}
          videoUrl={_game.video_url}
          onLoad={onImageLoad}
          onError={onImageError}
          alt={realSrc}
          className={style.transitionImage}
        >
          {/* 已安装 */}
          {showInstalledState && isInstalled && (
            <SvgIcon size={1} icon="installed" className="installed" />
          )}
          {/* 操作按钮-打开游戏或云 */}
          {(hasItemBtn || detailClickJSX) && (
            <div className={style.buttonOverlay}>
              <div className={style.buttonWrap}>
                {detailClickJSX}
                {isGooglePlayAndEnterGP ? null : buttonJSX}
              </div>
            </div>
          )}
        </AspectImage>
      </div>
    )
  }, [
    showCloudBtn,
    showPlayBtn,
    handleOnClick,
    showInstalledState,
    isInstalled,
    _game,
    hasItemBtn,
    imgPlaceholderClass,
    onImageLoad,
    onImageError,
    imageUrl,
    isVideo,
    lazyRoot,
    lazyRootMargin,
    hasHoverAction,
    hoverImageUrl,
    isHovering,
    lazy,
    intl,
    hasDetailAction,
    isJapan,
    enableObserve,
  ])
  const _onRootClick = (evt) => {
    if (isGooglePlayAndEnterGP) {
      handleOnClick({
        evt,
        gameType: 'simulator',
        actionCode: 'openGooglePlay',
        redirectCode: 'AppGoogleSearch',
      })
    }
    onRootClick(_game, evt)
  }
  return (
    <div className={classNames([style.container, className])} onClick={_onRootClick}>
      {imageJSX}
      {_game.game_name && (
        <p className={classNames([nameClass, style.gameName])}>{_game.game_name}</p>
      )}
      {showSubTitle && (_game.category || showTypeIcon) && (
        <div className={style.footerPanel}>
          <span className={classNames([categoryClass, style.category])}>{_game.category}</span>
          {showTypeIcon && (
            <div className={style.gameType}>
              {_game.showCloudBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="cloud" className={['c-white']} />
                </div>
              )}
              {_game.showPlayBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="android" className={['c-white']} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default GameListItem
