/**
 * List all topics for browse by collection
 */

import React from 'react'
import GridLayout from '@/components/layout/gridLayout/Index'
import CollectionItem from '@/components/common/Collection/Item'
import bgColorList from '@/components/common/Collection/bgColor'
const CollectionList = (props) => {
  return (
    <GridLayout
      {...props}
    >
      {(item, index) => {
        return <CollectionItem styleObj={bgColorList[index % bgColorList.length]} topic={item} />
      }}
    </GridLayout>
  )
}

export default CollectionList
