import { ncmAppsTransformer } from '@/transformers'
import * as types from './constant'
import { getNCMAppList } from '@/apis/apps'

// Fetch cloud game apps action
export const fetchCloudGameApps = () => {
  return async (dispatch) => {
    try {
      const _result = await getNCMAppList()
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: ncmAppsTransformer(_result.apps || []),
      })
    } catch (error) {
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: [],
      })
    }
  }
}
