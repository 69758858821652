import { GOOGLE_PLAY_PCK } from '@/tool/constant'
import { GP_STORE_ENABLE } from './project.config'


function getActionText(intl, id) {
  return intl ? intl.formatMessage({ id }) : id
}
/**
 *  Get the app {actionText,actionCode,gameType,redirectCode:(AppGoogleSearch,AppDetail,AppLauncher)} according to the configuration
 * @param {object} intl Intl
 * @param {object} param1 {isInstalled:is it installed,app:app info,platforms:user-specified priority startiup way,location:execution location}
 * @returns object
 */
export const getButtonText = (
  intl,
  {
    // whether id installed,default false
    isInstalled = false,
    app,
    platforms,
    isGotoAppDetailPage = true
  }
) => {
  const {
    isInstallCDN = false,
    isOpenInBrowser = false,
    button_text,
    package_name,
    game_play_preference = ['local'],
  } = app
  // whether is google-store app
  const isGooglePlay = package_name === GOOGLE_PLAY_PCK
  if (isGooglePlay) {
    return {
      actionText: getActionText(intl, GP_STORE_ENABLE ? 'visitStore' : 'openGooglePlay'),
      actionCode: 'openGooglePlay',
      gameType: 'simulator',
      icon: 'bluestacks',
      redirectCode: GP_STORE_ENABLE ? 'AppGoogleSearch' : 'AppLauncher',
      buttonType: 'primaryChange',
    }
  }
  // whether is Go Rogue app
  if (app.isGoRogue) {
    return {
      actionText: getActionText(intl, 'viewDetails'),
      actionCode: 'goRogue',
      icon: 'bluestacks',
      redirectCode: 'InstantPlay',
      buttonType: 'primaryChange',
    }
  }
  // whether is Premium app
  if (app.isPremium) {
    return {
      actionText: getActionText(intl, 'viewDetails'),
      actionCode: 'goPremium',
      icon: 'bluestacks',
      redirectCode: 'PremiumGame',
      buttonType: 'primaryChange',
    }
  }
  // whether is an experience pre-registered app
  if (isOpenInBrowser) {
    return {
      actionText: button_text,
      actionCode: button_text,
      icon: 'bluestacks',
      redirectCode: 'AppLauncher',
      buttonType: 'primaryChange',
    }
  }
  // get app startup way(cloud/local)
  const bootActionType = platforms || game_play_preference[0]
  if (bootActionType === 'cloud') {
    const id = isGotoAppDetailPage ? 'viewDetails' : 'playInBrowser'
    return {
      actionText: getActionText(intl, id),
      actionCode: 'playOnCloud',
      gameType: 'cloud',
      icon: 'nowgg',
      redirectCode: isGotoAppDetailPage ? 'AppDetail' : 'AppLauncher',
      buttonType: id === 'viewDetails' ? 'primaryChange' : 'pinkChange',
    }
  }
  if (bootActionType === 'local') {
    // 是否
    const buttonType = isGotoAppDetailPage ? 'primaryChange' : 'successChange'
    const redirectCode = isGotoAppDetailPage ? 'AppDetail' : 'AppLauncher'
    // whether is installed
    if (isInstalled) {
      return {
        actionText: getActionText(intl, isGotoAppDetailPage ? 'viewDetails' : 'playOnAppPlayer'),
        actionCode: 'playOnAppPlayer',
        gameType: 'simulator',
        icon: 'bluestacks',
        redirectCode,
        buttonType,
      }
    } else {
      if (isInstallCDN) {
        return {
          actionText: getActionText(intl, isGotoAppDetailPage ? 'viewDetails' : 'installInAppPlayer'),
          actionCode: 'installInAppPlayer',
          gameType: 'simulator',
          icon: 'bluestacks',
          redirectCode,
          buttonType,
        }
      } else {
        return {
          actionText: getActionText(intl, isGotoAppDetailPage ? 'viewDetails' : 'installViaGooglePlay'),
          actionCode: 'installViaGooglePlay',
          gameType: 'simulator',
          icon: 'bluestacks',
          redirectCode,
          buttonType,
        }
      }
    }
  }
  return {}
}
