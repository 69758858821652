import { useEffect } from "react"
import { qwebApi } from "@/apis/qwebApi"
import { containerValueFinder } from '@/tool'

const Hook = (target,{path='common'}) => {
    useEffect(() => {
        // 滚动事件
        const _onContainerScroll = (e) => {
            if (e.target) {
                qwebApi({
                    event: 'onPageScroll',
                    data: {
                        x: window.getRealMeasurement(e.target.scrollLeft),
                        y: window.getRealMeasurement(e.target.scrollTop),
                        path
                    },
                })
            }
        }
        const scrollContainer = containerValueFinder(target)
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', _onContainerScroll, false)
        }
        return () =>
            scrollContainer && scrollContainer.removeEventListener('scroll', _onContainerScroll)
    }, [])
}

export default Hook
