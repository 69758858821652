import React from 'react'
import { useIntl } from 'react-intl'
import ResultItem from '../result/ResultItem'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import style from './index.module.scss'
const PopularAppsList = ({ list }) => {
  const intl = useIntl()
  return list.length > 0 ? (
    <div className={style.popularGameContainer}>
      <div className={style.header}>
        <div className={style.headerLeft}>
          <SvgIcon className={style.icon} size={1} icon="hot-stroke" />
          <span className={style.text}>{intl.formatMessage({ id: 'mostPopularGames' })}</span>
        </div>
      </div>
      <ul className={style.content}>
        {list.slice(0, 7).map((app) => {
          return <ResultItem as="li" key={app.package_name} app={app} />
        })}
      </ul>
    </div>
  ) : null
}

export default PopularAppsList
