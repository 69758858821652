import React, { useMemo, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Virtual } from 'swiper'
import classNames from 'classnames'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import FormattedText from '@/components/common/FormattedText/Index'
import SwiperSwitch from '@/components/common/SwiperSwitch/Index'
import { usePxToPx } from '@/hook/useViewport'
import './index.scss'

SwiperCore.use([Navigation, Virtual])

const CarouselLayout = ({
  className,
  titleId,
  title,
  list,
  carouselKeyId = 'carousel',
  slideConfig = {},
  slidesPerView = 4,
  slidesPerGroup = slidesPerView,
  children,
  renderHeader,
  keyId,
  gap = 24,
}) => {
  const nextElClassName = `swiper-button-next_${carouselKeyId}`
  const prevElClassName = `swiper-button-prev_${carouselKeyId}`

  const gridGap = usePxToPx(gap)

  const swiperRef = useRef(null)
  // swiper配置参数
  const settings = useMemo(() => {
    return {
      simulateTouch: false,
      spaceBetween: gridGap,
      navigation: {
        nextEl: `.${nextElClassName}`,
        prevEl: `.${prevElClassName}`,
        disabledClass: 'disabled',
      },
      virtual: true,
      slidesPerView,
      slidesPerGroup,
      ...slideConfig,
    }
  }, [slidesPerView, prevElClassName, nextElClassName, slidesPerGroup, slideConfig, gridGap])

  if (!Array.isArray(list) || list.length === 0) {
    return null
  }
  return (
    <div className={classNames(['carousel-layout', className])}>
      {(renderHeader &&
        (typeof renderHeader === 'function'
          ? renderHeader({
              nextElClassName,
              prevElClassName,
              swiperSwitch: (
                <SwiperSwitch prevElClassName={prevElClassName} nextElClassName={nextElClassName} />
              ),
            })
          : renderHeader)) || (
        <BaseHeader>
          <FormattedText title={title} id={titleId} />
        </BaseHeader>
      )}
      <Swiper {...settings} ref={swiperRef}>
        {list.map((banner, index) => {
          return (
            <SwiperSlide virtualIndex={index} key={banner[keyId] || index}>
              {(slideOptions) => children(banner, { index, slideOptions, swiperRef })}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default CarouselLayout
