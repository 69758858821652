import React, { useMemo } from 'react'
import classNames from 'classnames'
import Rate from 'rc-rate'
import 'rc-rate/assets/index.css'
import AspectImage from '@/components/common/Image/AspectImage'
import ActionButton from '@/components/actionButton/Index'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

import './index.scss'

const BannerOne = ({ banner, handleOnClick, isInstalled = false, className, buttonMode }) => {
  const subInfoJSX = useMemo(() => {
    if (banner) {
      const segments = []
      if (banner.category) {
        segments.push(<span key={'categery'}>{banner.category}</span>)
      }
      if (banner.platform_name) {
        segments.push(<span key={'platform'}>{banner.platform_name}</span>)
      }
      for (let index = segments.length - 1; index > 0; index--) {
        segments.splice(index, 0, <span key={`split_${index}`} className='split'></span>)
      }
      return segments
    }
    return null
  }, [banner])
  return <div className={classNames(['bannerOne-container', className])}>
    <div>
      <div className='bannerOne-header'>
        <AspectImage
          className='image'
          src={banner.icon_url}
          alt="homePageBannerImage"
        />
        <div className='desc'>
          <div className='rate-wrap'>
            <Rate
              disabled
              defaultValue={0}
              value={parseFloat(banner.app_rating) || 0}
              style={{ fontSize: 14 }}
              allowHalf
              character={<SvgIcon size={1} icon="star" />}
              allowClear={false}
            />
          </div>
          <div className='app-info'>{subInfoJSX}</div>
        </div>
      </div>
      <h1 className='bannerOne-title'>{banner.game_name}</h1>
    </div>
    <ActionButton
      mode={buttonMode}
      app={banner}
      isInstalled={isInstalled}
      handleOnClick={handleOnClick}
    />
  </div>
}

export default BannerOne
