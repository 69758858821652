import { getFormDataBySearch } from '../tool'
import fetch from '../fetch'
import store from '@/store'

window.__CLIENT_CONFIG__ = window.__CLIENT_CONFIG__ || {
  systemConfig: {},
  x_version: '',
  commonConfig: {},
}
/**
 * Fetch rotation-banner request
 * @returns Promise
 */
export const getRotationAppsApi = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/game_center_rotation`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
  })
}

/**
 * Fetch popular cloud game request
 * @returns Promise
 */
export const getPopularCloudGameApps = () => {
  const url = `${window.__HOST_CONFIG__.cloudHost}/api/apps/v1/popular/apps/list`
  return fetch({
    method: 'get',
    url,
    params: {
      ...window.__CLIENT_CONFIG__.commonConfig,
      utm_source: 'now.gg-partner',
      utm_medium: 'client',
      utm_campaign: 'BlueStacksX',
    },
    noValidate: true,
  })
}

/**
 * Fetch popular cloud game request
 * @returns Promise
 */
export const getNCMAppList = () => {
  const url = `${window.__HOST_CONFIG__.cloudHost}/ncm/appsc/v1/bsxGetAppsList`
  return fetch({
    method: 'get',
    url,
    params: {
      ...window.__CLIENT_CONFIG__.commonConfig,
      utm_source: 'now.gg-partner',
      utm_medium: 'client',
      utm_campaign: 'BlueStacksX',
    },
    noValidate: true,
  })
}

/**
 * Fetch game-center-home request
 * @returns Promise
 */
export const getHomeAppsApi = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/game_center_home`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    showDisconnect: true,
  })
}

/**
 * Fetch topic detail info
 * @param {Object} param0 topic id
 * @returns Promise
 */
export const getTopicInfo = ({ topic_id }) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/app_center_topic_data`
  return fetch({
    method: 'get',
    url: url,
    params: {
      topic_id,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
  })
}

/**
 * Fetch search apps
 * @returns Promise
 */
export const getSearchAppsApi_v4 = (query, config = {}) => {
  // const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/search/v4`
  const url = `${window.__HOST_CONFIG__.baseHost}/launcher/search/v1`
  const userState = store.getState().user.userInfo
  const { player_guid = '', player_version = '' } = window.__CLIENT_CONFIG__.player_info
  return fetch({
    method: 'get',
    url,
    params: {
      query,
      search_client: 'launcher',
      user_id: userState?.userid,
      user_email: userState?.email,
      player_guid,
      player_version,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
    ...config,
  })
}

/**
 * Fetch side_panel request
 * @returns Promise
 */
export const getSidepanelAppsApi = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/bs3/side_panel`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    noValidate: true,
  })
}

/**
 * Fetch feature apps request
 * @returns Promise
 */
export const getFeatureAppsApi = (extraData = {}) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/bs4/get_cpi_apps_for_quest`
  return fetch({
    method: 'get',
    url,
    params: {
      ...extraData,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
  })
}

/**
 * Check the country of the ip request
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const getIpCountry = (params = {}) => {
  // const url = `${localServerHost}/get_ip_local`
  const url = `${window.__HOST_CONFIG__.baseHost}/api/getcountryforip`
  return fetch({
    method: 'get',
    url,
    params,
    noValidate: true,
  })
}

/**
 * Fetch app info request
 * @param pkg
 * @param country
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const getAppInfoFromCloud = (params = {}) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/app/info`
  return fetch({
    method: 'get',
    url,
    params: {
      ...params,
      ...window.__CLIENT_CONFIG__.commonConfig,
    },
    noValidate: true,
  })
}

/**
 * Fetch suggested apps
 * @returns Promise
 */
export const getSuggestedApps = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/launcher/get_suggested_apps`
  return fetch({
    method: 'post',
    url: url,
    data: getFormDataBySearch({
      width: 300,
      launcher_version: window.__CLIENT_CONFIG__.commonConfig.bsxversion,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    noValidate: true,
  })
}
