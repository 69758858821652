import React, { useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import FormattedText from '@/components/common/FormattedText/Index'
import style from './describtion.module.scss'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
const Describtion = ({ className, describtion }) => {
  const contentRef = useRef(null)
  const contentInnerRef = useRef(null)
  const [isExpand, setIsExpand] = useState(false)
  const [showToggleBtn, setShowToggleBtn] = useState(true)

  useLayoutEffect(() => {
    if (contentInnerRef.current) {
      const contentHeight = contentInnerRef.current.clientHeight
      if (isExpand) {
        contentRef.current.style.height = `${contentHeight}px`
      } else {
        contentRef.current.style.height = `${Math.min(contentHeight, 120)}px`
      }
      if (contentHeight <= 120) {
        setShowToggleBtn(false)
      } else {
        setShowToggleBtn(true)
      }
    }
  }, [isExpand])
  return (
    <div className={classNames([style.container, className])}>
      <BaseHeader className={style.header}>
        <FormattedText id='overview' />
      </BaseHeader>
      <h2 className={style.subHeader}>{describtion.game_description_heading}</h2>
      <div ref={contentRef} className={classNames([style.content, { [style.expand]: isExpand }])}>
        <div
          ref={contentInnerRef}
          className={classNames(['opaqueWhiteText'])}
          dangerouslySetInnerHTML={{
            __html: (
              describtion.game_description ||
              describtion.google_play_description ||
              ''
            ).replace(/<a.*?href=['"](.*?)['"].*?>(.*?)<\/a>/g, '$2'),
          }}
        />
      </div>
      {showToggleBtn && (
        <span className={style.showMore} onClick={() => setIsExpand(!isExpand)}>
          <FormattedMessage id={isExpand ? 'showLess' : 'showMore'} />
          <SvgIcon
            className={classNames([style.toggleIcon, { [style.rotationIcon]: isExpand }])}
            size={1}
            icon="arrow-down-circle"
          />
        </span>
      )}
    </div>
  )
}

export default React.memo(Describtion)
